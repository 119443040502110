import {
  SEND_EVENT_INFORMATION,
  SEND_SCHEDULES,
  SEND_DATA_TO_CONFIRM,
  SUBMIT_REQUEST_SUCCESS,
  SUBMIT_REQUEST_ERROR,
  GET_REQUESTS_DATA,
  GET_REQUESTS_LOADING,
  GET_REQUEST_DATA,
  DELETE_REQUEST_RESPONSE,
  TOGGLE_SAVE_REQUEST,
  SEND_REJECT_REQUEST,
  TOGGLE_LOADING,
  GET_REQUEST_MEEETING_DATA,
  GET_MEETING_DETAILS,
  CLOSE_MODAL,
} from "./actionTypes";

const initialState = {
  pageCount: 0,
  totalCount: 0,
  page: 1,
  error: "",
  loading: false,
  data: {
    eventName: "",
    activity: "",
    otherActivity: "",
    sectorId: "",
    sectorName: "",
    otherSector: "",
    format: "",
    nationalAnthems: "",
    musicalPresentations: "",
    songLinks: "",
    additionalRemarks: "",
    schedules: [],
    saveRequestSuccess: false,
    meetings: [],
    event: null,
    requestStatusId: null,
  },
  requestList: [],
};

const Request = (state = initialState, action) => {
  switch (action.type) {
    case SEND_EVENT_INFORMATION:
      state = {
        ...state,
        error: "",
        loading: false,
        data: action.payload,
      };
      break;
    case SEND_SCHEDULES:
      state = {
        ...state,
        error: "",
        loading: false,
        data: action.payload,
      };
      break;
    case SEND_DATA_TO_CONFIRM:
      state = {
        ...state,
        error: "",
        loading: false,
        data: {
          ...action.payload,
          requestStatusId: 1,
          rejectReason: null,
          reasonAcceptPartially: null,
        },
      };
      break;
    case SUBMIT_REQUEST_SUCCESS:
      state = {
        ...state,
        error: "",
        data: {
          eventName: "",
          activity: "",
          otherActivity: "",
          sectorId: "",
          sectorName: "",
          format: "",
          nationalAnthems: "",
          musicalPresentations: "",
          songLinks: "",
          additionalRemarks: "",
          schedules: [],
        },
        saveRequestSuccess: true,
        error: "",
      };
      break;

    case GET_REQUESTS_DATA:
      state = {
        ...state,
        error: "",
        pageCount: action.payload.pageCount,
        totalCount: action.payload.totalCount,
        page: action.payload.page,
        requestList: action.payload,
        loading: false,
      };
      break;

    case GET_REQUESTS_LOADING:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case SUBMIT_REQUEST_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;

    case GET_REQUEST_DATA:
      state = {
        ...state,
        error: "",
        data: action.payload,
        loading: false,
      };
      break;
    case DELETE_REQUEST_RESPONSE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;

    case SEND_REJECT_REQUEST:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;

    case TOGGLE_SAVE_REQUEST:
      state = {
        ...state,
        saveRequestSuccess: !state.saveRequestSuccess,
        loading: false,
      };
      break;

    case TOGGLE_LOADING:
      state = {
        ...state,
        error: "",
        loading: action.payload,
      };
      break;

    case GET_REQUEST_MEEETING_DATA:
      state = {
        ...state,
        error: "",
        data: {
          ...state.data,
          meetings: action.payload,
        },
      };
      break;

    case GET_MEETING_DETAILS:
      state = {
        ...state,
        error: "",
        data: {
          ...state.data,
          event: action.payload,
        },
      };
      break;

    case CLOSE_MODAL:
      return {
        ...state,
        data: {
          ...state.data,
          event: null,
        },
      };

    default:
      state = {
        ...state,
      };
      break;
  }
  return state;
};

export default Request;
