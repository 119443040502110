import { api } from '../../../services/api'
import { processErrorMessage } from '../../../helpers/errors'
import {
  FORGET_PASSWORD_LOADING,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
} from './actionTypes'
import { toast } from 'react-toastify'

export const userForgetPassword = (user, history) => (dispatch) =>  {
  dispatch({  type: FORGET_PASSWORD_LOADING })
  api.post('account/forgot_password', user)
    .then(response => {
      toast.success( 
        'Você receberá um e-mail instruções para criar uma nova senha. Caso não encontre seu e-mail na caixa de entrada, verifique na pasta de Spam',
        'E-mail enviado'
      )
      dispatch({
        type: FORGET_PASSWORD_SUCCESS,
        payload: response.data,
      })
    })
    .catch(error => {
      processErrorMessage(dispatch, error, FORGET_PASSWORD_ERROR)
    })
}