import {
  GET_STAKEHOLDER_TYPES_LOADING,
  GET_STAKEHOLDER_TYPES_SUCCESS,
  GET_INTERPRETERS_LOADING,
  GET_INTERPRETERS_SUCCESS,
  API_ERROR,
  GET_PARTIAL_SCHEDULES_LIST_LOADING,
  GET_PARTIAL_SCHEDULES_LIST_SUCCESS,
  GET_SECTORS_LIST_LOADING,
  GET_SECTORS_LIST_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  stakeholderTypes: [],
  interpreters: [],
  interpretersSummary: [],
  attendanceRequestItems: [],
  error: "",
  sectors: [],
  loading: false,
};

const shared = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STAKEHOLDER_TYPES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_STAKEHOLDER_TYPES_SUCCESS:
      return {
        ...state,
        stakeholderTypes: action.payload,
        loading: false,
      };
    case GET_INTERPRETERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_INTERPRETERS_SUCCESS:
      return {
        ...state,
        interpreters: action.payload,
        interpretersSummary: action.payload.map(interpreter => ({
          label: interpreter.name,
          value: interpreter.id,
        })),
        loading: false,
      };
    case GET_PARTIAL_SCHEDULES_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_PARTIAL_SCHEDULES_LIST_SUCCESS:
      return {
        ...state,
        attendanceRequestItems: action.payload,
        loading: false,
      };

    case GET_SECTORS_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SECTORS_LIST_SUCCESS:
      return {
        ...state,
        sectors: action.payload,
        loading: false,
      };

    case API_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default shared;
