import { format } from "date-fns";

export const getDateStr = (date = undefined) => {
  const currentdate = date || new Date();
  return `${currentdate.getDate()}-${
    currentdate.getMonth() + 1
  }-${currentdate.getFullYear()} ${currentdate.getHours()}-${currentdate.getMinutes()}`;
};

export const formatDateTime = (date = undefined) => {
  return format(new Date(date), "dd/MM/yyyy HH:mm");
};
