import { api } from '../../services/api'
import { processErrorMessage } from '../../helpers/errors'
import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_SECTORS_SUCCESS,
  GET_USER_SECTORS_SUCCESS,
  UPDATE_SECTORS_SUCCESS,
  UPDATE_ROLES_SUCCESS,
  API_ERROR,
  SEND_EMAIL_SUCCESS,
  GET_USERS_FAIL,
  SAVE_USER_LOADING,
  GET_USERS_SUCCESS,
  GET_USERS_LOADING,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  ENABLE_USER_SUCCESS,
  DISABLE_USER_SUCCESS,
  TOGGLE_USER_MODAL_ACTIVE,
  GET_ROLES_SUCCESS,
} from './actionTypes'
import {
  EMPLOYEE,
  EMPLOYEE_PSY,
  ADMIN,
  currentRoleAction,
} from 'constants/roles'
import { newRoleAuthUser, changeRole } from '../auth/changeRole/actions'

import { toast } from 'react-toastify'
import { values } from 'lodash'


export const getUsers = (page = 1, name = '') => dispatch => {
  dispatch({ type: GET_USERS_LOADING })
  api.get(`account/users?page=${page}&name=${name}`, { withCredentials: true })
    .then(response => {
      dispatch({
        type: GET_USERS_SUCCESS,
        payload: response.data,
      })
    })
    .catch(error => {
      processErrorMessage(dispatch, error, API_ERROR)
    })
}

export const getSectors = () => dispatch => {
  api.get(`sectors/`, { withCredentials: true })
    .then(response => {
      dispatch({
        type: GET_SECTORS_SUCCESS,
        payload: response.data,
      })
    })
    .catch(error => {
      processErrorMessage(dispatch, error, API_ERROR)
    })
}

export const getUserSectors = (userId) => (dispatch) => {
  api.get(`usersSectors/${userId}`, { withCredentials: true })
    .then((response) => {
      dispatch({
        type: GET_USER_SECTORS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      processErrorMessage(dispatch, error, API_ERROR);
    });
};

export const getRoles = () =>
  dispatch => {
    api
      .get(`roles/`, { withCredentials: true })
      .then(response => {
        dispatch({
          type: GET_ROLES_SUCCESS,
          payload: response.data,
        })
      })
      .catch(error => {
        processErrorMessage(dispatch, error, API_ERROR)
      })
  }



export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const sendEmail = user => dispatch => {
  api
    .post('account/forgot_password', user)
    .then(response => {
      toast.success(
        'Você receberá em instantes um e-mail com instruções para criar uma nova senha'
      )
      dispatch({
        type: SEND_EMAIL_SUCCESS,
        payload: response.data,
      })
    })
    .catch(error => {
      processErrorMessage(dispatch, error, FORGET_PASSWORD_ERROR)
    })
}

export const getUserProfile = () => ({
  type: GET_USER_PROFILE,
})

export const getUserProfileSuccess = userProfile => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: userProfile,
})

export const getUserProfileFail = error => ({
  type: GET_USER_PROFILE_FAIL,
  payload: error,
})

export const addNewUser = (user, history) => dispatch => {
  dispatch({ type: SAVE_USER_LOADING })
  api
    .post('account/sign_up', user)
    .then(response => {
      dispatch({
        type: ADD_USER_SUCCESS,
        payload: response.data,
      })
      toast.success('Usuário criado com sucesso')
      dispatch(getUsers())
      history.push('/contacts-list')
    })
    .catch(error => {
      processErrorMessage(dispatch, error, API_ERROR)
    })
}

export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

export const updateUser = (user, history, userSectors, role, userRoles) => dispatch => {
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  dispatch({ type: SAVE_USER_LOADING });
  api.put('account/update', user)
    .then(response => {
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: response.data,
      });
      toast.success('Usuário atualizado com sucesso');
      history.push('/contacts-list');
    })
    .catch(error => {
      processErrorMessage(dispatch, error, API_ERROR);
    });

  if (Array.isArray(userRoles.roles) && userRoles.roles.some(role => role === EMPLOYEE || role === ADMIN || role === EMPLOYEE_PSY)) {
    api.put('usersSectors/update/', userSectors, { withCredentials: true })
      .then(response => {
        dispatch({
          type: UPDATE_SECTORS_SUCCESS,
          payload: response.data,
        });
      })
      .catch(error => {
        processErrorMessage(dispatch, error, API_ERROR);
      });
  }

  api.put('usersRoles/update/', userRoles, { withCredentials: true })
    .then(response => {
      dispatch({
        type: UPDATE_ROLES_SUCCESS,
        payload: response.data,
      });
    })
    .catch(error => {
      processErrorMessage(dispatch, error, API_ERROR);
    });

  if (Array.isArray(userRoles.roles) && !userRoles.roles.includes(user.role) && authUser.email === user.email) {
    const payload = {
      values: { roleId: userRoles.roles[0] }

    }
    dispatch(changeRole(history, payload))
    dispatch(newRoleAuthUser(history))
  }
}

export const blockUser = user => dispatch => {
  toast.success('Usuário bloqueado')
}

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

export const disableUser = user => dispatch => {
  const { id } = user
  api
    .put(`account/disable_user/${id}`)
    .then((response) => {
      dispatch({
        type: DISABLE_USER_SUCCESS,
        payload: response.data,
      })
      history.push('/contacts-list')
    })
    .catch(error => {
      processErrorMessage(dispatch, error, API_ERROR)
    })
}

export const enableUser = user => dispatch => {
  const { id } = user
  api
    .put(`account/enable_user/${id}`)
    .then((response) => {
      dispatch({
        type: ENABLE_USER_SUCCESS,
        payload: response.data,
      })
      history.push('/contacts-list')
    })
    .catch(error => {
      processErrorMessage(dispatch, error, API_ERROR)
    })
}

export const disableUserSuccess = user => ({
  type: DISABLE_USER_SUCCESS,
  payload: user,
})

export const enableUserSuccess = user => ({
  type: ENABLE_USER_SUCCESS,
  payload: user,
})

export const apiReqError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const toggleSaveUserModal = () => ({
  type: TOGGLE_USER_MODAL_ACTIVE
})
