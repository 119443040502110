import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  allowedToRoles,
  user,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (
          (isAuthProtected && !localStorage.getItem('authUser')) ||
          (user && allowedToRoles && !allowedToRoles.includes(user.role))
        ) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )
        }
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  user: PropTypes.object,
  allowedToRoles: PropTypes.array
}

const mapStateToProps = state => {
  const { userData } = state.User
  return { user: userData }
}


export default connect(mapStateToProps, {})(AppRoute)