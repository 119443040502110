import React, { Component } from "react"

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"

import ApexRadial from "./ApexRadial"

class MonthlyEarning extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  render() {
    return (
      <React.Fragment>
        {" "}
        <Card>
          <CardBody>
            <CardTitle className="mb-4 h4">Compromissos planejados</CardTitle>
            <Row>
              <Col sm="6">
                <p className="text-muted">Este mês</p>
                <h3>{this.props.schedulesTotal.thisMonth}</h3>

                {this.props.schedulesTotal.percent != 0 && !isNaN(this.props.schedulesTotal.percent) ? (
                  
                <p className="text-muted">
                  {
                    this.props.schedulesTotal.percent > 0 ?
                    (<span className="text-success me-2">
                    {" "}
                   { this.props.schedulesTotal.percent}% <i className="mdi mdi-arrow-up"></i>{" "}
                  </span>) :
                  (<span className="text-danger me-2">
                    {" "}
                   { this.props.schedulesTotal.percent}% <i className="mdi mdi-arrow-down"></i>{" "}
                  </span>)
                  }
                 
                  em comparação ao mês passado
                </p>): ''}
                <div className="mt-4">
                  <Link
                    to="/appointments"
                    className="btn btn-primary btn-sm"
                  >
                    Ver relatórios <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </Col>
              <Col sm="6">
                <div className="mt-4 mt-sm-0">
                  <ApexRadial />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default MonthlyEarning
