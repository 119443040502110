import React, { Component } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { logoutUser } from "../../../store/actions";
import ChangeRoleModal from "./ChangeRoleModal";
import { getUserRoles, toggleChangeRole } from "../../../store/auth/changeRole/actions";
import user1 from "../../../assets/icons/user.png";
import { connect } from "react-redux";

const getUserName = () => {
  const authUser = localStorage.getItem("authUser");
  if (authUser) {
    return JSON.parse(authUser);
  }
  return null;
}

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      name: "Admin",
      selectedRole: null,
      role: null,
    };
    this.userData = getUserName();
    this.toggleChangeRoleModal = this.toggleChangeRoleModal.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }));
  }

  toggleChangeRoleModal() {
    if (this.userData) {
      this.props.onGetUserRoles(this.userData.id);
      this.props.onToggleChangeRole();
    }
  }

  componentDidMount() {
    if (this.userData) {
      this.setState({ name: this.userData.username });
      this.props.onGetUserRoles(this.userData.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success && this.userData) {
      this.setState({ name: this.userData.username });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item"
            id="page-header-user-dropdown"
            tag="button"
          >
            <img
              className="rounded-circle header-profile-user"
              src={user1}
              alt="Header Avatar"
            />{" "}
            <span className="d-none d-xl-inline-block ms-1">
              {this.state.name}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem tag={Link} to="/profile">
              <i className="bx bx-user font-size-16 align-middle me-1" />
              {this.props.t("Profile")}
            </DropdownItem>
            <div className="dropdown-divider" />
            <DropdownItem onClick={this.toggleChangeRoleModal}>
              <i className="bx dripicons-user-group font-size-16 align-middle me-1" />
              {this.props.t("ChangeRole")}
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                this.props.logoutUser(this.props.history);
              }}
            >
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span>{this.props.t("Logout")}</span>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <ChangeRoleModal
          userRole={this.userData ? this.userData.role : null}
          isOpen={this.props.changeRoleModal}
          toggle={this.toggleChangeRoleModal}
          userRoles={this.props.userRoles}
        />
      </React.Fragment>
    );
  }
}

ProfileMenu.propTypes = {
  t: PropTypes.any,
  success: PropTypes.string,
  history: PropTypes.object,
  userRoles: PropTypes.array,
  error: PropTypes.string,
  loading: PropTypes.bool,
  toggleChangeRole: PropTypes.func,
  changeRoleModal: PropTypes.bool,
  onGetUserRoles: PropTypes.func,
};

const mapStateToProps = state => ({
  success: state.Profile.success,
  userRoles: state.changeRole.userRoles,
  error: state.changeRole.error,
  loading: state.changeRole.loading,
  changeRoleModal: state.changeRole.changeRoleModal
});

const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch(logoutUser()),
  onGetUserRoles: (userId) => dispatch(getUserRoles(userId)),
  onToggleChangeRole: () => dispatch(toggleChangeRole()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProfileMenu))
);
