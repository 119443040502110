import {
  FORGET_PASSWORD_LOADING,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false
}

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD_LOADING:
      return {
        ...state,
        error: "",
        loading: true,
      }
    case FORGET_PASSWORD:
      return {
        ...state,
        error: "",
      }
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false
      }
    case FORGET_PASSWORD_ERROR:
      return { 
        ...state, 
        error: action.payload,
        loading: false
      }
    default:
      return { ...state }
  }
}

export default forgetPassword
