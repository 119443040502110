import {
  API_SUCCESS,
  API_FAIL,
  GET_CHARTS_DATA,
  GET_CHARTS_INTERPRETERS_DATA,
  GET_CHARTS_CENTERS_DATA,
  GET_TOTAL_SCHEDULES_DATA,
  GET_TOTAL_SOLICITATIONS_DATA,
  GET_TOTAL_INTERPRETERS_AND_YEAR_SCHEDULES,
} from "./actionTypes";

const INIT_STATE = {
  chartsData: [],
  chartsDataInterpreters: [],
  chartsDataCenters:[],
  schedules: {
    thisMonth: 0,
    lastMonth: 0,
    percent: 0,
  },
  solicitations: {
    total: 0,
    totalApproved: 0,
    totalPeding: 0,
  },
  misc: {
    totalInterpreters: 0,
    totalSchedules: 0,
    name: ''
  }
};

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CHARTS_DATA:
          return {
            ...state,
            chartsData: action.payload.data,
          };
        case GET_TOTAL_SCHEDULES_DATA:
          return {
            ...state,
            schedules: action.payload.data,
          };
        case GET_CHARTS_CENTERS_DATA:
          
          return {
            ...state,
            chartsDataCenters: action.payload,
          };
        case GET_CHARTS_INTERPRETERS_DATA:
          return {
            ...state,
            chartsDataInterpreters: action.payload,
          };
        case GET_TOTAL_SOLICITATIONS_DATA:
          return {
            ...state,
            solicitations: action.payload.data,
          };
        case GET_TOTAL_INTERPRETERS_AND_YEAR_SCHEDULES:
          return {
            ...state,
            misc: action.payload.data,
          };
        default:
          return state;
      }

    case API_FAIL:
      switch (action.payload.actionType) {
        case GET_CHARTS_DATA:
          return {
            ...state,
            earningChartDataError: action.payload.error,
          };

        default:
          return state;
      }
    default:
      return state;
  }
};

export default Dashboard;
