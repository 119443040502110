import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ConfirmRequest from "./NewRequestTabs/ConfirmRequest";
import classnames from "classnames";
import {
  getRequestById,
  cancelRequest,
  getRequests,
  getRequestMeetings,
  getSelectedMeetingDetails,
  onCloseModal,
} from "../../store/actions";
import { connect } from "react-redux";
import CancelAttendanceModal from "components/Common/CancelAttendance";
import InformationMailModal from "components/Common/InformationMailModal";
import Calendar from "pages/Calendar";
import FullCalendar from "@fullcalendar/react";

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
//css
import "@fullcalendar/bootstrap/main.css";
import "@fullcalendar/bootstrap/main.css";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";

class ViewRequest extends Component {
  constructor(props) {
    super(props);
    this.calendarComponentRef = React.createRef();
    this.state = {
      cancelModal: false,
      selectedRequest: {},
      selectedMeeting: {},
      informationModal: false,
      modal: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle(selectedMeeting) {
    if (this.props.event == null && selectedMeeting != null) {
      const { getSelectedMeetingDetails } = this.props;
      getSelectedMeetingDetails(selectedMeeting.id);
    } else {
      const { onCloseModal } = this.props;
      onCloseModal();
    }
  }

  componentDidMount() {
    const id = this.props.location.pathname.split("/")[2];
    this.props.getRequestById(id);
  }

  handleDatesSet = info => {
    const id = this.props.location.pathname.split("/")[2];
    const view = info.view;
    const dateIntervalStart = view.activeStart;
    const dateIntervalEnd = view.activeEnd;
    const { getRequestMeetings } = this.props;
    getRequestMeetings(id, dateIntervalStart, dateIntervalEnd);
  };

  handleEventClick = arg => {
    const selectedMeeting = arg.event;
    this.toggle(selectedMeeting);
  };

  render() {
    const id = this.props.location.pathname.split("/")[2];
    document.title = "Minhas solicitações | Atendimento NUPI";
    return (
      <React.Fragment>
        <CancelAttendanceModal
          show={this.state.cancelModal}
          requestName={this.props.data.eventName}
          onDeleteClick={() => {
            this.props.cancelRequest(id, this.props.history);
            this.setState({ cancelModal: false, informationModal: true });
            this.props.getRequests("", 1);
          }}
          onCloseClick={() =>
            this.setState({ cancelModal: false, selectedRequest: {} })
          }
        />
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Tables" breadcrumbItem="Minhas solicitações" />

            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Nova Solicitação</h4>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({
                            current: true,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === 1,
                            })}
                          >
                            <span className="number">1.</span> Solicitação
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={1} className="body">
                        <TabPane tabId={1}>
                          {this.props.data != undefined ? (
                            <>
                              <ConfirmRequest />
                              {this.props.data.requestStatusId != 1 && this.props.data.requestStatusId != 4 && (
                                <Container fluid>
                                  <Row>
                                    <Col>
                                      <Card>
                                        <CardBody>
                                          <Row>
                                            <Col className="col-12">
                                              <FullCalendar
                                                ref={this.calendarComponentRef}
                                                plugins={[
                                                  BootstrapTheme,
                                                  dayGridPlugin,
                                                  interactionPlugin,
                                                ]}
                                                slotDuration={"00:15:00"}
                                                handleWindowResize
                                                themeSystem="bootstrap"
                                                locale={ptBrLocale}
                                                eventTimeFormat={{
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                  meridiem: false,
                                                }}
                                                timeZone="UTC"
                                                headerToolbar={{
                                                  left: "prev,next today",
                                                  center: "title",
                                                  right:
                                                    "dayGridMonth,dayGridWeek,dayGridDay",
                                                }}
                                                events={this.props.data.meetings}
                                                editable={false}
                                                selectable
                                                eventClick={this.handleEventClick}
                                                datesSet={this.handleDatesSet}
                                              />

                                              <Modal
                                                isOpen={
                                                  this.props.data.event != null
                                                }
                                                className={this.props.className}
                                                id="event-modal"
                                                onExit={() => this.toggle(null)}
                                                onClosed={() => this.toggle(null)}
                                              >
                                                <ModalHeader
                                                  toggle={() => this.toggle(null)}
                                                  tag="h4"
                                                >
                                                  {this.props.data.event && (
                                                    <>
                                                      {this.props.data.eventName}
                                                    </>
                                                  )}
                                                </ModalHeader>
                                                <ModalBody>
                                                  <>
                                                    <Row>
                                                      <Col xs={12}>
                                                        <div className="mb-3 ">
                                                          {this.props.data
                                                            .event && (
                                                              <>
                                                                <h5>
                                                                  Inicio:{" "}
                                                                  {
                                                                    this.props.data
                                                                      .event
                                                                      .scheduleConfirmations
                                                                      .date
                                                                  }
                                                                </h5>
                                                                <h5>
                                                                  Fim:{" "}
                                                                  {
                                                                    this.props.data
                                                                      .event
                                                                      .scheduleConfirmations
                                                                      .dateEnd
                                                                  }
                                                                </h5>

                                                                <h5>
                                                                  Intérpretes:{" "}
                                                                  {this.props.data.event.interpreters.map(
                                                                    (
                                                                      interpreter,
                                                                      index
                                                                    ) => {
                                                                      return (
                                                                        <span
                                                                          key={
                                                                            index
                                                                          }
                                                                        >
                                                                          {
                                                                            interpreter.name
                                                                          }
                                                                          , {""}
                                                                        </span>
                                                                      );
                                                                    }
                                                                  )}
                                                                </h5>
                                                              </>
                                                            )}
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col>
                                                        <div className="text-end">
                                                          <button
                                                            type="button"
                                                            className="btn btn-light me-2"
                                                            onClick={() =>
                                                              this.toggle(null)
                                                            }
                                                          >
                                                            Fechar
                                                          </button>
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </>
                                                </ModalBody>
                                              </Modal>
                                            </Col>
                                          </Row>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </Container>
                              )}

                            </>
                          ) : (
                            <span>loading </span>
                          )}
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <li
                          className={
                            this.state.activeTab === 1
                              ? "previous disabled"
                              : "previous"
                          }
                        >
                          <Link to="/my-requests">Voltar</Link>
                        </li>
                        {this.props.data.requestStatusId === 1 && (
                          <li className="btn danger">
                            <Button
                              className="btn-danger"
                              disabled={this.props.loading}

                              onClick={e => {
                                this.setState({
                                  cancelModal: true,
                                });
                              }}
                            >
                              {this.props.loading && "Aguarde..." || "Cancelar Solicitação"}
                            </Button>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { error, loading, data, requestList } = state.Request;
  return { error, loading, data, requestList };
};

export default withRouter(
  connect(mapStateToProps, {
    getRequestById,
    cancelRequest,
    getRequests,
    getRequestMeetings,
    getSelectedMeetingDetails,
    onCloseModal,
  })(ViewRequest)
);
