import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  SEND_EMAIL_LOADING,
  API_ERROR,
  GET_USERS_LOADING,
  ADD_USER_SUCCESS,
  SAVE_USER_LOADING,
  ADD_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DISABLE_USER_SUCCESS,
  ENABLE_USER_SUCCESS,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  TOGGLE_USER_MODAL_ACTIVE,
  GET_SECTORS_SUCCESS,
  GET_USER_SECTORS_SUCCESS,
  UPDATE_SECTORS_SUCCESS,
  GET_ROLES_SUCCESS,
  UPDATE_ROLES_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  sectors: [],
  userSectors: [],
  roles: [],
  stakeholderTypes: [],
  pageCount: 0,
  totalCount: 0,
  page: 1,
  userProfile: {},
  error: "",
  saveUserModalActive: false,
  loading: false,
}

const contacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_LOADING:
      return {
        ...state,
        error: "",
        loading: true
      }
    case SEND_EMAIL_LOADING:
      return {
        ...state,
        error: "",
        loading: true
      }
    case SAVE_USER_LOADING:
      return {
        ...state,
        error: "",
        loading: true
      }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        error: "",
        users: action.payload.users,
        pageCount: action.payload.pageCount,
        totalCount: action.payload.totalCount,
        page: action.payload.page,
        loading: false
      }

    case GET_USER_SECTORS_SUCCESS:
      return {
        ...state,
        userSectors: action.payload,

      }

    case GET_SECTORS_SUCCESS:
      return {
        ...state,
        sectors: action.payload,
      }

    case UPDATE_SECTORS_SUCCESS:
      return {
        ...state,
        users: state.users.map(user => {
          if (user.id.toString() === action.payload[0].userId.toString()) {
            return {
              ...user,
              sectors: action.payload,
            };
          }
          return user;
        }),
        errorMessage: undefined,
        saveUserModalActive: false,
        loading: false
      };

    case GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
      }

    case UPDATE_ROLES_SUCCESS:
      
      return {
        ...state,
        users: state.users.map(user => {
          if (user.id.toString() === action.payload[0].userId.toString()) {
            return {
              ...user,
              roles: action.payload,
            };
          }
          return user;
        }),
        errorMessage: undefined,
        saveUserModalActive: false,
        loading: false
      };

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_USER_SUCCESS:
      return {
        ...state,
        error: "",
        users: [...state.users, action.payload],
        errorMessage: undefined,
        saveUserModalActive: false,
        loading: false
      }
    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        saveUserModalActive: false
      }
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        error: "",
        userProfile: action.payload,
      }
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        error: "",
        users: state.users.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
        errorMessage: undefined,
        saveUserModalActive: false,
        loading: false
      }
    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        saveUserModalActive: false
      }
    case DISABLE_USER_SUCCESS:
      return {
        ...state,
        error: "",
        users: state.users.map(user => {
          if (user.id.toString() === action.payload.id.toString()) {
            user.deletedAt = Date.now()
          }
          return user
        })
      }
    case ENABLE_USER_SUCCESS:
      return {
        ...state,
        error: "",
        users: state.users.map(user => {
          if (user.id.toString() === action.payload.id.toString()) {
            user.deletedAt = null
          }
          return user
        })
      }
    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case API_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case TOGGLE_USER_MODAL_ACTIVE:
      return {
        ...state,
        error: "",
        saveUserModalActive: !state.saveUserModalActive,
      }
    default:
      return state
  }
}

export default contacts
