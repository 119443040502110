import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE ,RESET_PROFILE_FLAG, EDIT_PROFILE_STARTED, CHANGE_PASSWORD_PROFILE, CHANGE_PASSWORD_PROFILE_ERROR, CHANGE_PASSWORD_PROFILE_STARTED, CHANGE_PASSWORD_PROFILE_SUCCESS} from "./actionTypes"
import { processErrorMessage } from '../../../helpers/errors'
import { api } from "../../../services/api"

export const editProfile = (data) => (dispatch) => {
  dispatch({ type: EDIT_PROFILE_STARTED })
  api.put('account/profile', data)
    .then(response => {
      dispatch({
        type: PROFILE_SUCCESS,
        payload: response.data,
      })
      let authUser = JSON.parse(localStorage.getItem('authUser'))
      authUser = {...authUser, name: response.data.name, email: response.data.email}
      localStorage.removeItem('authUser')
      localStorage.setItem('authUser', JSON.stringify(authUser))
    })
    .catch(error => {
      processErrorMessage(dispatch, error, PROFILE_ERROR)
    })
}

export const changePassword = (data) => (dispatch) =>{
  dispatch({ type: CHANGE_PASSWORD_PROFILE_STARTED })
  api.put('account/change_password', data)
    .then(response => {
      dispatch({
        type: CHANGE_PASSWORD_PROFILE_SUCCESS,
        payload: response.data,
      })
    })
    .catch(error => {
      processErrorMessage(dispatch, error, CHANGE_PASSWORD_PROFILE_ERROR)
    })
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}
