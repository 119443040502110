import { api } from "services/api"
import {
  APPOINTMENTS_FAIL,
  GET_EVENTS_LIST_SUCCESS,
  EVENTS_LOADING,
  CANCEL_SCHEDULE_SUCCESS,
  RESTART_SCHEDULE_SUCCESS,
  GET_INTERPRETERS_LIST_SUCCESS,
  CHANGE_INTERPRETER_SUCCESS,
  TOGGLE_CHANGE_MODAL_ACTIVE,
  API_ERROR

} from "./actionTypes"
import { toast } from "react-toastify"
import { processErrorMessage } from "helpers/errors"

export const getEventsList = (data) => (dispatch) => {
  dispatch({ type: EVENTS_LOADING })
  api.get(`/schedule_confirmation/eventsList?page=${data.page}&dateIntervalStart=${data.dateIntervalStart}&dateIntervalEnd=${data.dateIntervalEnd}`, { withCredentials: true })
    .then(response => {
      if (response && response.status === 200) {
        dispatch({ type: GET_EVENTS_LIST_SUCCESS, payload: response.data })
      } else {
        dispatch({ type: APPOINTMENTS_FAIL, payload: payload.data })
      }
    })
    .catch(function (error) {
      if (error.response) {
        toast.warning(error.response.data.errors[0].message)
      }
      dispatch({ type: APPOINTMENTS_FAIL, payload: error.response.data })
    })
}

export const cancelSchedule = (id) => (dispatch) => {
  dispatch({ type: EVENTS_LOADING })
  api.get(`/schedule_confirmation/cancelSchedule?id=${id}`, { withCredentials: true })
    .then(response => {
      if (response && response.status === 200) {
        dispatch({ type: CANCEL_SCHEDULE_SUCCESS, payload: response.data })
        toast.success("Compromisso cancelado com sucesso");
      } else {
        dispatch({ type: APPOINTMENTS_FAIL, payload: response.data })
      }
    })
    .catch(function (error) {
      if (error.response) {
        toast.warning(error.response.data.errors[0].message)
      }
      dispatch({ type: APPOINTMENTS_FAIL, payload: error.response })
    })
}

export const restartSchedule = (id) => (dispatch) => {
  dispatch({ type: EVENTS_LOADING })
  api.get(`/schedule_confirmation/restartSchedule?id=${id}`, { withCredentials: true })
    .then(response => {
      if (response && response.status === 200) {
        dispatch({ type: RESTART_SCHEDULE_SUCCESS, payload: response.data })
      } else {
        dispatch({ type: APPOINTMENTS_FAIL, payload: response.data })
      }
    })
    .catch(function (error) {
      if (error.response) {
        toast.warning(error.response.data.errors[0].message)
      }
      dispatch({ type: APPOINTMENTS_FAIL, payload: error.response })
    })
}

export const getInterpretersList = requestId => dispatch => {
  api
    .get(`interpreter/all/${requestId}`)
    .then(response => {
      dispatch({
        type: GET_INTERPRETERS_LIST_SUCCESS,
        payload: response.data,
      });
    })
    .catch(error => {
      processErrorMessage(dispatch, error, API_ERROR);
    });
};

export const changeInterpreter = (payload) => dispatch => {
  api
    .post(`/schedule_confirmation/change_interpreter`, payload)
    .then(response => {
      dispatch({
        type: CHANGE_INTERPRETER_SUCCESS,
        payload: response.data,
      });
      toast.success("Intérprete alterado com sucesso!");
    })
    .catch(error => {
      if (error.response) {
        
        toast.warning(error.response.data.error.replace("Error:", ""))
        processErrorMessage(dispatch, error.response.data.error, API_ERROR);
      }

    });
};

export const toggleChangeInterpreter = () => dispatch => (
  dispatch({ type: TOGGLE_CHANGE_MODAL_ACTIVE })
)



