import { change } from "redux-form"
import {
  GET_EVENTS_LIST_SUCCESS,
  APPOINTMENTS_FAIL,
  CANCEL_SCHEDULE_SUCCESS,
  EVENTS_LOADING,
  RESTART_SCHEDULE_SUCCESS,
  GET_INTERPRETERS_LIST_SUCCESS,
  CHANGE_INTERPRETER_SUCCESS,
  TOGGLE_CHANGE_MODAL_ACTIVE,
  API_ERROR
} from "./actionTypes"

const INIT_STATE = {
  eventsList: [],
  event: null,
  error: {},
  loading: false,
  currentPage: 1,
  totalCount: 0,
  pageCount: 0,
  changeInterpreterModal: false,
  interpretersList: []
}

const AppointmentsList = (state = INIT_STATE, action) => {
  switch (action.type) {
    case EVENTS_LOADING:
      return {
        ...state,
        error: "",
        loading: true
      }

    case GET_EVENTS_LIST_SUCCESS:
      return {
        ...state,
        eventsList: action.payload.schedules,
        totalCount: action.payload.totalCount,
        pageCount: action.payload.pageCount,
        currentPage: action.payload.currentPage,
        loading: false
      }

    case APPOINTMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case CANCEL_SCHEDULE_SUCCESS:
      return {
        ...state,
        error: "",
        eventsList: state.eventsList.map(event => {
          if (event.id.toString() === action.payload.toString()) {
            event.scheduleConfirmations.status = 2
          }
          return event;
        }),
        loading: false,
      }
    case RESTART_SCHEDULE_SUCCESS:
      return {
        ...state,
        error: '', // Limpar erros
        eventsList: state.eventsList.map(event => {
          if (event.id.toString() === action.payload.toString()) {
            event.scheduleConfirmations.status = 1; // Marcar como reiniciado
          }
          return event;
        }),
        loading: false, // Definir loading para false
      };

    case GET_INTERPRETERS_LIST_SUCCESS:
      return {
        ...state,
        interpretersList: action.payload,
        loading: false
      }
    case CHANGE_INTERPRETER_SUCCESS:
      const newEventsList = state.eventsList.map(event => {
        if (event.scheduleConfirmationId === action.payload.scheduleConfirmationId) {
          const newInterpreter = state.interpretersList.find(
            interpreter => interpreter.id === action.payload.newInterpreterId
          );
          return {
            ...event,
            interpreterId: newInterpreter.id,
            interpreters: newInterpreter
          };
        }

        return event;
      });

      return {
        ...state,
        eventsList: newEventsList,
        error: "",
        loading: false,
        changeInterpreterModal: false,
      };

    case TOGGLE_CHANGE_MODAL_ACTIVE:
      return {
        ...state,
        error: "",
        changeInterpreterModal: !state.changeInterpreterModal,
      }

    case API_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default AppointmentsList
