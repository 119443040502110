import { addAuthorizationHeader, api } from "../../../services/api";
import { processErrorMessage } from "../../../helpers/errors";
import {
  REGISTER_USER_LOADING,
  REGISTER_USER_SUCCESSFUL,
  API_ERROR,
  GET_STAKEHOLDER_TYPES_SUCCESS,
} from "./actionTypes";
import { toast } from "react-toastify";

export const registerUser = (user, history) => dispatch => {
  dispatch({ type: REGISTER_USER_LOADING, payload: true });
  api
    .post("account/sign_up", user)
    .then(response => {
      toast.success("Cadastro realizado com sucesso");
      dispatch({
        type: REGISTER_USER_SUCCESSFUL,
        payload: response.data,
      });
      localStorage.setItem("authUser", JSON.stringify(response.data));
      addAuthorizationHeader();
      history.push("/my-requests");
    })
    .catch(error => {
      processErrorMessage(dispatch, error, API_ERROR);
    });
};

export const getStakeholderTypes = () => dispatch => {
  api
    .get("stakeholder_types/all")
    .then(response => {
      dispatch({
        type: GET_STAKEHOLDER_TYPES_SUCCESS,
        payload: response.data,
      });
    })
    .catch(error => {
      processErrorMessage(dispatch, error, API_ERROR);
    });
};
