import {
  MUTATE_USER,
  LOGIN_STARTED,
  LOGIN_SUCCESS,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  userData: undefined
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_STARTED:
      state = {
        ...state,
        error: "",
        loading: true
      }
      break
    case MUTATE_USER:
        state = {
          ...state,
          error: "",
          userData: action.payload
        }
        break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        userData: action.payload
      }
      break
    case LOGOUT_USER_SUCCESS:
      state = { 
        ...state, 
        error: "",
        userData: undefined 
      }
      break
    case API_ERROR:
      state = { 
        ...state, 
        error: action.payload, 
        loading: false 
      }
      break
    default:
      state = { 
        ...state 
      }
      break
  }
  return state
}

export default login
