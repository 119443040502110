import React from "react";
import {
    Label
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup'

export class AdditionalInformation extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Formik
                enableReinitialize
                initialValues={{
                    nationalAnthems: 'false',
                    musicalPresentations: 'false',
                    songLinks: '',
                    additionalRemarks: '',
                }}
                validationSchema={Yup.object().shape({
                    nationalAnthems: Yup.string().required(),
                    musicalPresentations: Yup.string().required(),
                    songLinks: Yup.string().when('musicalPresentations', {
                        is: 'true',
                        then: Yup.string().required('Por favor digite o link das músicas')
                    }),
                    additionalRemarks: Yup.string()
                })}
                onSubmit={values => {
                    this.props.submit(values);
                }}
            >
                {({ values, errors, status, touched }) => (
                    <Form className='form-horizontal'>
                        <div className="mb-3">
                            <h6 className="font-size-13 font-weight-500">Haverá exibição do hino nacional?</h6>
                            <Label
                                className="form-check-label"
                                for="nationalAnthemsYes"
                                style={{ marginRight: '15px' }}
                            >
                                <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="nationalAnthems"
                                    id="nationalAnthemsYes"
                                    value="true"
                                />
                                <span style={{ marginLeft: '5px' }}>Sim</span>
                            </Label>
                            <Label
                                className="form-check-label"
                                for="nationalAnthemsNo"
                                style={{ marginRight: '15px' }}
                            >
                                <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="nationalAnthems"
                                    id="nationalAnthemsNo"
                                    value="false"
                                />
                                <span style={{ marginLeft: '5px' }}>Não</span>
                            </Label>
                        </div>

                        <div className="mb-3">
                            <h6 className="font-size-13 font-weight-500">Haverá apresentação musical?</h6>
                            <Label
                                className="form-check-label"
                                for="musicalPresentationsYes"
                                style={{ marginRight: '15px' }}
                            >
                                <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="musicalPresentations"
                                    id="musicalPresentationsYes"
                                    value="true"
                                />
                                <span style={{ marginLeft: '5px' }}>Sim</span>
                            </Label>
                            <Label
                                className="form-check-label"
                                for="musicalPresentationsNo"
                                style={{ marginRight: '15px' }}
                            >
                                <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="musicalPresentations"
                                    id="musicalPresentationsNo"
                                    value="false"
                                />
                                <span style={{ marginLeft: '5px' }}>Não</span>
                            </Label>
                        </div>
                        {values.musicalPresentations == "true" &&
                            (<div className='mb-3' >
                                <Label for="songLinks">
                                    Informe o link do google drive para download das musicas (Habilite o compartilhamento publico dos arquivos)
                                </Label>
                                <Field
                                    name='songLinks'
                                    id='songLinks'
                                    type='text'
                                    placeholder='Informe o link do google drive para download das musicas'
                                    className={
                                        'form-control' +
                                        (errors.songLinks && touched.songLinks
                                            ? ' is-invalid'
                                            : '')
                                    }
                                />
                                <ErrorMessage
                                    name='songLinks'
                                    component='div'
                                    className='invalid-feedback'
                                />
                            </div>
                            )}
                        <div className='mb-3'>
                            <Label for='additionalRemarks' className='form-label'>
                                Observações adicionais
                            </Label>
                            <Field
                                name='additionalRemarks'
                                as='textarea'
                                id='additionalRemarks'
                                placeholder='Insira aqui as observações'
                                rows='3'
                                className={
                                    'form-control' +
                                    (errors.additionalRemarks && touched.additionalRemarks
                                        ? ' is-invalid'
                                        : '')
                                }
                            />
                            <ErrorMessage
                                name='additionalRemarks'
                                component='div'
                                className='invalid-feedback'
                            />
                        </div>
                        <button
                            className='d-none'
                            type='submit'
                            id="submit-additional-information"
                        ></button>
                    </Form>
                )}
            </Formik>
        );
    }
}