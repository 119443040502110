import { api } from "services/api";
import {
    API_SUCCESS,
    API_FAIL,
    GET_CHARTS_DATA,
    GET_CHARTS_CENTERS_DATA,
    GET_CHARTS_INTERPRETERS_DATA,
    GET_TOTAL_SCHEDULES_DATA,
    GET_TOTAL_SOLICITATIONS_DATA,
    GET_TOTAL_INTERPRETERS_AND_YEAR_SCHEDULES,
} from "./actionTypes";

export const apiSuccess = (actionType, data) => ({
    type: API_SUCCESS,
    payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
    type: API_FAIL,
    payload: { actionType, error },
});

export const getChartsData = (periodType) => ({
    type: GET_CHARTS_DATA,
    payload: periodType
});

export const getChartsDataCenters = (payload) => ({
    type: GET_CHARTS_CENTERS_DATA,
    payload: payload   
});

export const getChartsDataInterpreters = (payload) => ({
    type: GET_CHARTS_INTERPRETERS_DATA,
    payload: payload
});

export const getTotalSchedulesData = () => (dispatch) => {
    api.get(`counters/schedules`, { withCredentials: true }).then(data => {
        dispatch(apiSuccess(GET_TOTAL_SCHEDULES_DATA, data.data))
    }).catch(error => {
        dispatch(apiFail(API_FAIL, error))
    })
}

export const getTotalSolicitations = (payload) => (dispatch) => {
    api.get(`counters/attendance_requests`, { params: payload, withCredentials: true })
        .then(data => {
            dispatch(apiSuccess(GET_TOTAL_SOLICITATIONS_DATA, data.data))
        })
        .catch(error => {
            dispatch(apiFail(API_FAIL, error))
        });
}

export const onGetTotalInterpretersAndYearSchedules = () => (dispatch) => {
    api.get(`counters/interpreters_schedules`, { withCredentials: true }).then(data => {
        const user = JSON.parse(localStorage.getItem('authUser'))
        data.data.name = user.name
        dispatch(apiSuccess(GET_TOTAL_INTERPRETERS_AND_YEAR_SCHEDULES, data.data))
    }
    ).catch(error => {
        dispatch(apiFail(API_FAIL, error))
    })
}

export const onGetRequestsForChart = (payload) => (dispatch) => {
    const period = payload.values;
    const type = payload.periodType;
    api.get(`counters/schedules/chart_data/${type}`, {params: period, withCredentials: true }).then(data => {
            dispatch(apiSuccess(GET_CHARTS_DATA, data.data))
        }).catch(error => {
            dispatch(apiFail(API_FAIL, error))
        })
}

export const onGetRequestsForChartInterpreters = (payload) => (dispatch) => {
    const period = payload.values;
    api.get(`counters/schedules/chart_data_interpreters/`, {params: period, withCredentials: true }).then(data => {
            dispatch(apiSuccess(GET_CHARTS_INTERPRETERS_DATA, data.data))
        }).catch(error => {
            dispatch(apiFail(API_FAIL, error))
        })
}

export const onGetRequestsForChartCenters = (payload) => (dispatch) => {
    const period = payload.values;
    api.get(`counters/schedules/chart_data_centers/`, {params: period, withCredentials: true }).then(data => {
            dispatch(apiSuccess(GET_CHARTS_CENTERS_DATA, data.data))
        }).catch(error => {
            dispatch(apiFail(API_FAIL, error))
        })
}