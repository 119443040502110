import moment from 'moment';
import React from 'react'

import {
    Card,
    CardBody,
    Col
} from "reactstrap";

export class ScheduleConfirm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Card outline color="primary" className="border border-primary mb-2">
                <CardBody>
                    <Col lg="6">
                        <h6>{moment(this.props.schedule.startDate).format('DD/MM/YYYY')} das {this.props.schedule.startTime}  às {this.props.schedule.endTime}</h6>
                    </Col>
                    <Col lg="6">
                        {this.props.schedule.repeat === 'not-repeat' ?
                            <h6>Não se repete</h6> :
                            <h6>Repete {this.props.schedule.repeat == 'daily' ?
                                'diariamente' : this.props.schedule.repeat == 'weekly' ?
                                    'semanalmente' : 'mensalmente'} até {moment(this.props.schedule.endDate).format('DD/MM/YYYY')}</h6>
                        }
                    </Col>
                    <Col lg="6">
                        {!this.props.schedule.interval ?
                            <h6>Sem intervalo</h6> :
                            <h6>Intervalo das {this.props.schedule.startInterval} às {this.props.schedule.endInterval}</h6>
                        }
                    </Col>
                </CardBody>
            </Card >
        );
    }
}


