import { configureStore } from '@reduxjs/toolkit'
import { applyMiddleware, compose } from 'redux'
import ReduxThunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'

import rootReducer from './reducers'
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()
const store = configureStore({
    reducer: rootReducer,
    middleware: [ ReduxThunk, sagaMiddleware ]
})
sagaMiddleware.run(rootSaga)

export default store
