const formatTypes = {
    'remote': 'Remoto',
    'presential': 'Presencial'
  };
  
  const statusTypes = {
    1: 'Confirmado',
    2: 'Cancelado'
  };
  
  export { formatTypes, statusTypes };