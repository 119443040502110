/* USERS */
export const GET_REQUESTS = "GET_REQUESTS"
export const GET_REQUESTS_LOADING = "GET_REQUESTS_LOADING"
export const GET_REQUESTS_SUCCESS = "GET_REQUESTS_SUCCESS"
export const GET_REQUESTS_FAIL = "GET_REQUESTS_FAIL"
export const GET_EXPORT_SUCCESS = "GET_EXPORT_SUCCESS"

export const API_ERROR = "API_ERROR"





