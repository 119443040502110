import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Alert, Card, CardBody, Col, Container, Row, Label } from 'reactstrap'

// Redux
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

// React-aria
import { } from 'react-aria'

//Social Media Imports
// import { GoogleLogin } from 'react-google-login'
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

//Import config
// import { facebook, google } from '../../config'

import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

// actions
import { apiError, loginUser, socialLogin } from '../../store/actions'

// import images
import profile from '../../assets/images/profile-img.png'
import logo from '../../assets/images/logo.svg'
import lightlogo from '../../assets/images/logo-light.svg'

class Login extends Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  eye = () => {
    let passwordInput = document.getElementById('password')
    let inputTypeIsPassword = passwordInput.type == 'password'
    if (inputTypeIsPassword) {
      passwordInput.setAttribute('type', 'text')
    }
    else {
      passwordInput.setAttribute('type', 'password')
    }
  }

  handleCheckboxKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const checkbox = document.getElementById('customControlInline');
      if (checkbox) {
        checkbox.checked = !checkbox.checked;
      }
    }
  };

  componentDidMount() {
    this.props.apiError('')
  }

  signIn = (res, type) => {
    const { socialLogin } = this.props
    if (type === 'google' && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      socialLogin(postData, this.props.history, type)
    } else if (type === 'facebook' && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      socialLogin(postData, this.props.history, type)
    }
  }

  //handleGoogleLoginResponse
  googleResponse = response => {
    this.signIn(response, 'google')
  }

  //handleTwitterLoginResponse
  twitterResponse = () => { }

  //handleFacebookLoginResponse
  facebookResponse = response => {
    this.signIn(response, 'facebook')
  }

  render() {
    const urlParams = new URLSearchParams(location.search);
    const error = urlParams.get('error');
    return (
      <React.Fragment>
        <div className='home-btn d-none d-sm-block'>
          <Link to='/' className='text-dark'>
            <i className='bx bx-home h2' />
          </Link>
        </div>
        <div className='account-pages my-5 pt-sm-5'>
          <Container>
            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card className='overflow-hidden'>
                  <div className='bg-primary bg-soft'>
                    <Row>
                      <Col className='col-7'>
                        <div className='text-primary p-4'>
                          <h5 className='text-primary'>Atendimento NUPI UFRB</h5>
                          <p>Acesse sua conta informando e-mail e senha</p>
                        </div>
                      </Col>
                      <Col className='col-5 align-self-end'>
                        <img src={profile} alt='' className='img-fluid' />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className='pt-0'>
                    <div className='auth-logo'>
                      <Link to='/' className='auth-logo-light'>
                        <div className='avatar-md profile-user-wid mb-4'>
                          <span className='avatar-title rounded-circle bg-light'>
                            <img
                              src={lightlogo}
                              alt=''
                              className='rounded-circle'
                              height='34'
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to='/' className='auth-logo-dark'>
                        <div className='avatar-md profile-user-wid mb-4'>
                          <span className='avatar-title rounded-circle bg-light'>
                            <img
                              src={logo}
                              alt=''
                              className='rounded-circle'
                              height='34'
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className='p-2'>
                      {this.props.error && this.props.error ? (
                        <Alert color='danger'>{this.props.error}</Alert>
                      ) : null}

                      {error && error === 'unauthorized' ? (
                        <Alert color='danger'>Oops! Parece que você não pode acessar a função solicitada. Por favor, faça o login novamente.</Alert>
                      ) : null}
                      <Formik
                        enableReinitialize
                        initialValues={{
                          email: '',
                          password: ''
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string().matches(/\S+@\S+\.\S+/, 'Digite um email válido').required('Por favor digite o seu e-mail'),
                          password: Yup.string()
                            .required('Por favor digite a sua senha')
                            .matches(/^(?=.*[a-zA-Z])(?=.*[0-9.,-]).{8,}$/, 'A senha precisa ter pelo menos 8 caracteres contendo letras e números')
                        })}
                        onSubmit={values => {
                          this.props.loginUser(values, this.props.history)
                        }}
                      >
                        {({ errors, status, touched }) => (

                          <Form className='form-horizontal'>
                            <div className='form-group mb-3'>
                              <Label for='email' className='form-label'>
                                E-mail
                              </Label>
                              <Field
                                name='email'
                                type='text'
                                className={
                                  'form-control' +
                                  (errors.email && touched.email
                                    ? ' is-invalid'
                                    : '')
                                }
                                id='email'
                              />
                              <ErrorMessage
                                name='email'
                                component='div'
                                className='invalid-feedback'
                              />
                            </div>

                            <div className='form-group mb-3'>
                              <Label for='password' className='form-label'>
                                Senha
                              </Label>
                              <div className='input-group auth-pass-inputgroup'>
                                <Field
                                  name='password'
                                  type='password'
                                  id='password'
                                  autoComplete='true'
                                  className={
                                    'form-control' +
                                    (errors.password && touched.password
                                      ? ' is-invalid'
                                      : '')
                                  }
                                />
                                <button
                                  className='btn btn-light'
                                  type='button'
                                  id='password-addon'
                                  onClick={this.eye}
                                  aria-label='Mostrar senha'
                                >
                                  <i className='mdi mdi-eye-outline'></i>
                                </button>
                                <ErrorMessage
                                  name='password'
                                  component='div'
                                  className='invalid-feedback'
                                />
                              </div>
                            </div>

                            <div className='form-check'>
                              <input
                                type='checkbox'
                                className='form-check-input'
                                id='customControlInline'
                                onKeyDown={this.handleCheckboxKeyPress}
                              />
                              <label
                                className='form-check-label'
                                htmlFor='customControlInline'
                              >
                                Permanecer conectado
                              </label>
                            </div>

                            <div className='form-group mt-3 d-grid'>
                              <button
                                className='btn btn-primary btn-block'
                                type='submit'
                                disabled={this.props.loading}
                                aria-label={this.props.loading ? 'Aguarde...' : 'Acessar conta'}
                              >
                                {this.props.loading ? 'Aguarde...' : 'Acessar conta'}
                              </button>
                            </div>

                            <div className='mt-4 text-center'>
                              <Link
                                to='/forgot-password'
                                className='text-muted'
                              >
                                <i className='mdi mdi-lock me-1' /> Esqueceu sua senha?
                              </Link>
                            </div>
                          </Form>

                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className='mt-5 text-center'>
                  <p>
                    <text>Não tem uma conta?</text>
                    <Link to='/register' className='fw-medium text-primary mx-1'>
                      Crie sua conta agora... é rapidinho!
                    </Link>
                  </p>
                  <p>
                    © {new Date().getFullYear()} <a href="https://ness.dev.br" target="_blank" rel="noreferrer">NESS</a>.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
  loading: PropTypes.bool
}

const mapStateToProps = state => {
  const { error, loading } = state.User
  return { error, loading }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
)
