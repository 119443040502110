
/* EVENTS */
export const GET_EVENTS_LIST_SUCCESS = "GET_EVENTS_SUCCESS"
export const APPOINTMENTS_FAIL = "APPOINTMENTS_FAIL"
export const GET_EVENTS_LIST = "GET_EVENTS"
export const EVENTS_LOADING = "GET_EVENTS_LOADING"
export const CANCEL_SCHEDULE_SUCCESS = "CANCEL_SCHEDULE_SUCCESS"
export const RESTART_SCHEDULE_SUCCESS = "RESTART_SCHEDULE_SUCCESS"
export const GET_INTERPRETERS_LIST_SUCCESS = "GET_INTERPRETERS_LIST_SUCCESS"
export const CHANGE_INTERPRETER_SUCCESS = "CHANGE_INTERPRETER_SUCCESS"
export const API_ERROR = "API_ERROR"
export const TOGGLE_CHANGE_MODAL_ACTIVE = "TOGGLE_CHANGE_MODAL_ACTIVE"
