import { addAuthorizationHeader, api } from "../../../services/api";
import { processErrorMessage } from "../../../helpers/errors";
import {
  GET_USER_ROLES_SUCCESS,
  API_ERROR,
  TOGGLE_CHANGE_MODAL_ACTIVE,
  CHANGE_ROLE_SUCCESS,
  CHANGE_ROLE_STARTED,
  NEW_AUTH_SUCCESS,
  NEW_AUTH_STARTED,
} from "./actionTypes";
import { ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_EMPLOYEE_PSY } from "constants/roles";

export const getUserRoles = (userId) => (dispatch) => {
  api
    .get(`usersRoles/${userId}`, { withCredentials: true })
    .then((response) => {
      dispatch({
        type: GET_USER_ROLES_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      processErrorMessage(dispatch, error, API_ERROR);
    });
};

export const changeRole = (history, payload) => (dispatch) => {
  dispatch({ type: CHANGE_ROLE_STARTED });
  const roleId = payload.values.roleId;
  api.put('usersRoles/changeCurrentRole', { roleId }, { withCredentials: true })
    .then(response => {
      dispatch({
        type: CHANGE_ROLE_SUCCESS,
        payload: response.data,
      });
      dispatch({ type: TOGGLE_CHANGE_MODAL_ACTIVE });
      dispatch(newRoleAuthUser(history));
    })
    .catch(error => {
      processErrorMessage(dispatch, error, API_ERROR);
    });
};

export const newRoleAuthUser = (history) => dispatch => {
  dispatch({ type: NEW_AUTH_STARTED });

  const handleAuthUser = (userData, path) => {
    localStorage.removeItem("authUser");
    localStorage.setItem("authUser", JSON.stringify(userData));
    addAuthorizationHeader();
    history.push(path);
    window.location.reload();
    toast.success('Papel alterado com sucesso!');
  };

  api.post("account/newRoleAuthUser")
    .then(response => {
      const userData = response.data;

      if (userData.role === ROLE_ADMIN) {
        handleAuthUser(userData, "/dashboard");
      } else if ([ROLE_EMPLOYEE, ROLE_EMPLOYEE_PSY].includes(userData.role)) {
        handleAuthUser(userData, "/schedules");
      } else {
        handleAuthUser(userData, "/my-requests");
      }

      dispatch({
        type: NEW_AUTH_SUCCESS,
        payload: response.data,
      });
    })
    .catch(error => {
      processErrorMessage(dispatch, error, API_ERROR);
    });
};




export const toggleChangeRole = () => (dispatch) => {
  dispatch({ type: TOGGLE_CHANGE_MODAL_ACTIVE });
};
