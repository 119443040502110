import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  FormGroup,
  Input,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";


import {

  rejectRequest,
} from "store/requests/actions";


class RejectRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestId: "",
      reason: "",
    };
  }

  componentDidMount() {
    const requestId = this.props.location.pathname.split("/")[2];
    this.setState({ requestId });
  }

  handleReasonChange = event => {
    this.setState({ reason: event.target.value });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="Nupi" breadcrumbItem="Rejeitar Solicitação" />
            <Row>
              <Col lg="12">
                {this.props.error && this.props.error ? (
                  <Alert color="danger">{this.props.error}</Alert>
                ) : null}
                {this.props.success && this.props.success ? (
                  <Alert color="success">{this.props.success}</Alert>
                ) : null}
              </Col>
            </Row>
            <FormGroup>
              <Card>
                <CardBody>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      reason: "",
                    }}
                    validationSchema={Yup.object().shape({
                      reason: Yup.string().required(
                        "Informe a razão do cancelamento."
                      ),
                    })}
                    onSubmit={values => {
                      this.props.onGetRejectRequests(this.state.requestId, values, this.props.history)
                    }}
                  >
                    {({ values, errors, status, touched }) => (
                      <Form className="form-vertical">
                        <div className="my-3">
                          <Card>
                            <CardBody>
                              <div>
                                <Label for="reason">Motivo da Rejeição</Label>
                                <Field name="reason">
                                  {({
                                    field,
                                    form: { touched, errors },
                                    meta,
                                  }) => (
                                    <div>
                                      <Input
                                        type="text"
                                        placeholder="Informe o motivo pelo qual não foi possível atender à esta solicitação"
                                        name="reason"
                                        {...field}
                                        className={
                                          "form-input" +
                                          (errors.reason && touched.reason
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      {meta.touched && meta.error && (
                                        <div className="error">
                                          {meta.error}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </Field>
                                <ErrorMessage
                                  name="reason"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                        <div className="text-center mt-4 d-flex flex-row-reverse">
                          <Button size="lg" type="submit" color="success" disabled={this.props.loading}>
                            {this.props.loading ? ("Aguarde..." ) : ("Rejeitar solicitação")}
                          </Button>
                          <button
                            className="d-none"
                            type="submit"
                            id="submit-event-information"
                          ></button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </FormGroup>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

RejectRequests.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  onGetPartialSchedulesList: PropTypes.func,
  onGetInterpreters: PropTypes.func,
  onAcceptIntegralRequest: PropTypes.func,
};

const mapStateToProps = ({ shared, Request }) => ({
  error: shared.error || Request.error,
  success: shared.success || Request.success,
  loading: Request.loading,
});

const mapDispatchToProps = dispatch => ({
  onGetRejectRequests: (id, data, history) => dispatch(rejectRequest(id, data, history)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RejectRequests)
);
