import { addAuthorizationHeader, api } from "../../../services/api";
import { processErrorMessage } from "../../../helpers/errors";
import {
  LOGIN_STARTED,
  LOGIN_SUCCESS,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
} from "./actionTypes";
import { ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_EMPLOYEE_PSY } from "constants/roles";

export const loginUser = (user, history) => dispatch => {
  dispatch({ type: LOGIN_STARTED });
  api
    .post("account/sign_in", user)
    .then(response => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response.data,
      });
      const userData = response.data;
      localStorage.setItem("authUser", JSON.stringify(userData));
      addAuthorizationHeader();
      if (userData.role === ROLE_ADMIN) {
        history.push("/dashboard");
      } else if (userData.role === ROLE_EMPLOYEE || userData.role === ROLE_EMPLOYEE_PSY) {
        history.push("/schedules");
      } else {
        history.push("/my-requests");
      }
    })
    .catch(error => {
      processErrorMessage(dispatch, error, API_ERROR);
    });
};

export const getUser = () => dispatch => {
  const user = localStorage.getItem("authUser");
  if (user && user !== "undefined") {
    dispatch({
      type: LOGIN_SUCCESS,
      payload: JSON.parse(user),
    });
  }
};

export const logoutUser = history => dispatch => {
  api
    .post("account/sign_out")
    .then(response => {
      dispatch({
        type: LOGOUT_USER_SUCCESS,
      });
      localStorage.removeItem("authUser");
      history.push("/login");
    })
    .catch(error => {
      processErrorMessage(dispatch, error, API_ERROR);
    });
};

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  };
};
