import axios from "axios";

axios.defaults.withCredentials = true;
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const addAuthorizationHeader = () => {
  const user = localStorage.getItem("authUser");
  if (user && user !== "undefined") {
    const userObject = JSON.parse(localStorage.getItem("authUser"));
    api.defaults.headers.common["Authorization"] = `Bearer ${userObject.token}`;
  } else {
    delete api.defaults.headers.common["Authorization"];
  }
};

api.interceptors.response.use(
  response => {
    return Promise.resolve(response);
  },
  error => {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem("authUser");
      window.location = "/login?error=unauthorized";
    }
    return Promise.reject(error);
  }
);

export { api, addAuthorizationHeader };
