import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Label, Row, Col, Form, Alert } from 'reactstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { connect } from 'react-redux';
import { getInterpretersList, changeInterpreter } from 'store/actions';
import { withRouter, Link } from 'react-router-dom';
import { error } from 'toastr';



class ChangeInterpreterModal extends Component {

  render() {
    const { isOpen, toggle, event, onSubmit, loading, errorMessage } = this.props;
    if (!event) return null;
    const { eventName, date } = event.scheduleConfirmations.attendanceRequest;

    return (
      <Modal isOpen={isOpen} toggle={toggle} className="change-interpreter-modal">
        <ModalHeader tag='h4'>
          Informe o intérprete para o compromisso {eventName} que ocorrerá {moment(date).format('DD/MM/YYYY')}
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{ interpreterId: '' }}
            validationSchema={Yup.object().shape({
              interpreterId: Yup.number().required('Selecione um intérprete'),
            })}
            onSubmit={values => {
              const payload = {
                scheduleId: event.id,
                scheduleConfirmationId: event.scheduleConfirmations.date,
                newInterpreterId: values.interpreterId,
              }
              this.onChangeInterpreter(payload)
            }}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form
              >
                <Row>
                  <Col className='col-12'>
                    <div className='mb-3'>
                      <Field
                        className='form-control'
                        as='select'
                        aria-label={'Caixa de seleção do intérprete'}
                        name='interpreterId'
                        onChange={event => setFieldValue('interpreterId', event.target.value)}
                      >
                        <option value=''>Selecione um intérprete</option>
                        {this.props.interpretersList ? this.props.interpretersList.map((interpreter, index) => (
                          <option key={index} value={interpreter.id}>
                            {interpreter.name}
                          </option>
                        )) : null}
                      </Field>
                      <ErrorMessage
                        name='interpreterId'
                        component='div'
                        className='invalid-feedback'
                      />
                    </div>
                    {errorMessage && (
                      <Alert color='danger'>{errorMessage}</Alert>
                    )}
                    <div className='text-end'>
                      <button
                        type='button'
                        onClick={() => {
                          const payload = {
                            scheduleId: event.id,
                            scheduleConfirmationId: event.scheduleConfirmationId,
                            newInterpreterId: values.interpreterId,
                          };
                          this.props.onChangeInterpreter(payload);

                        }}
                        className='btn btn-success me-2'
                        disabled={loading}
                        aria-label='Confirmar e mudar intérprete'
                      >
                        {loading ? 'Aguarde...' : 'Salvar'}
                      </button>
                      <button
                        type='button'
                        className='btn btn-danger'
                        onClick={this.props.toggle}
                        aria-label='Cancelar e fechar modal'
                      >
                        Cancelar
                      </button>
                    </div>

                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  interpretersList: state.appointmentsList.interpretersList,
  error: state.appointmentsList.error,
  loading: state.appointmentsList.loading,
})

const mapDispatchToProps = dispatch => ({
  onGetInterpreters: requestId => dispatch(getInterpretersList(requestId)),
  onChangeInterpreter: (payload) => dispatch(changeInterpreter(payload)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangeInterpreterModal))
