import React from "react";
import { Label } from "reactstrap";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import activityTypes from "../../../constants/activityTypes";
import { getSectorList } from "../../../store/shared/actions";
import { connect } from "react-redux";

class EventInformation extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { getSectorList } = this.props;
    getSectorList();
  }

  render() {
    const urlRegex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
    return (
      <Formik
        enableReinitialize
        initialValues={{
          eventName: "",
          activity: "",
          otherActivity: "",
          sectorId: "",
          sector: {},
          sectorName: "",
          otherSector: "",
          format: "presential",
          address: "",
        }}
        validationSchema={Yup.object().shape({
          eventName: Yup.string() .max(100, 'O nome deve ter menos de 100 caracteres').required("Por favor digite o nome do evento"),
          activity: Yup.string().required("Por favor selecione a atividade"),
          otherActivity: Yup.string().when("activity", {
            is: "Outra",
            then: Yup.string().required("Por favor digite a atividade"),
          }),
          sectorId: Yup.number().required("Por favor selecione um setor"),
          otherSector: Yup.string().when("sector", {
            is: "Outro",
            then: Yup.string().required("Por favor digite o setor"),
          }),
          format: Yup.string().required(
            "Por favor, selecione o formato do evento"
          ),
          remoteLink: Yup.string().when("format", {
            is: "remote",
            then: Yup.string()
              .matches(
                urlRegex,
                "Digite uma URL válida!"
              )
              .required("Por favor digite o link do evento"),
          }),
          location: Yup.string().when("format", {
            is: "presential",
            then: Yup.string().required(
              "Por favor, digite o nome do local do evento"
            ),
          }),
        })}
        onSubmit={values => {
          if (values.format === "presential") {
            values.address = values.location;
          } else if (values.format === "remote") {
            values.address = values.remoteLink;
          }

          values.sectorName = this.props.sectors.find(
            sector => sector.id == values.sectorId
            ).sector;
          
          values.sector = this.props.sectors.find(
            sector => sector.id == values.sectorId
            );

          this.props.submit(values);
        }}
      >
        {({ values, errors, status, touched }) => (
          <Form className="form-horizontal">
            <div className="mb-3">
              <Label for="eventName" className="form-label" >
                Nome (evento ou componente curricular)
              </Label>
              <Field
                name="eventName"
                id="eventName"
                type="text"
                aria-label="Nome do evento"
                className={
                  "form-control" +
                  (errors.eventName && touched.eventName ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="eventName"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="mb-3">
              <Label for="activity" className="form-label" >
                Tipo da atividade
              </Label>
              <Field
                as="select"
                id="activity"
                aria-label="Tipo de atividade"
                name="activity"
                className={
                  "form-select" +
                  (errors.activity && touched.activity ? " is-invalid" : "")
                }
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                {activityTypes.map((activity, index) => (
                  <option key={index} value={activity}>
                    {activity}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="activity"
                component="div"
                className="invalid-feedback"
              />
            </div>
            {values.activity == "Outra" && (
              <div className="mb-3">
                <Label for="otherActivity">Qual atividade?</Label>
                <Field
                  name="otherActivity"
                  id="otherActivity"
                  type="text"
                  aria-label="Outro tipo de atividade"
                  className={
                    "form-control" +
                    (errors.otherActivity && touched.otherActivity
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="otherActivity"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            )}
            <div className="mb-3">
              <Label for="sectorId" className="form-label">
                Setor solicitante
              </Label>
              <Field
                as="select"
                id="sectorId"
                aria-label="Setor solicitante"
                name="sectorId"
                className={
                  "form-select" +
                  (errors.sectorId && touched.sectorId ? " is-invalid" : "")
                }
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>

                {this.props.sectors.map((sector, index) => (
                  <option key={index} value={sector.id}>
                    {sector.sector}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="sectorId"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="mb-3">
              <h6 className="font-size-13 font-weight-500">Formato</h6>

              <Label
                className="form-check-label"
                for="presential"
                style={{ marginRight: "15px" }}
              >
                <Field
                  type="radio"
                  name="format"
                  id="presential"
                  value="presential"
                  className="form-check-input"
                />
                <span style={{ marginLeft: "5px" }}>Presencial</span>
              </Label>

              <Label className="form-check-label" for="remote">
                <Field
                  type="radio"
                  name="format"
                  id="remote"
                  value="remote"
                  className="form-check-input"
                />
                <span style={{ marginLeft: "5px" }}>Remoto</span>
              </Label>
            </div>
            {values.format === "remote" && (
              <div className="mb-3">
                <Label for="remoteLink" className="form-label">
                  Link do evento
                </Label>
                <Field
                  type="text"
                  aria-label="Link do evento"
                  name="remoteLink"
                  id="remoteLink"
                  className={
                    "form-control" +
                    (errors.remoteLink && touched.remoteLink
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="remoteLink"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            )}

            {values.format === "presential" && (
              <div className="mb-3">
                <Label for="location" className="form-label">
                  Local do evento
                </Label>
                <Field
                  type="text"
                  aria-label="Local do evento"
                  name="location"
                  id="location"
                  className={
                    "form-control" +
                    (errors.location && touched.location ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="location"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            )}

            <button
              className="d-none"
              type="submit"
              id="submit-event-information"
            ></button>
          </Form>
        )}
      </Formik>
    );
  }
}

EventInformation.propTypes = {
  getSectorList: PropTypes.func,
};

const mapStateToProps = state => {
  const { getSectorList, error, loading, sectors, } = state.shared;
  return { error, loading, sectors, getSectorList };
};

export default connect(mapStateToProps, { getSectorList })(EventInformation);
