import { api } from '../../../services/api'
import { processErrorMessage } from '../../../helpers/errors'
import {
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_LOADING
} from './actionTypes'
import { toast } from 'react-toastify'

export const userChangePassword = (token, password, history) => (dispatch) => {
  dispatch({ type: CHANGE_PASSWORD_LOADING })
  api.post(`account/change_password/${token}`, { password })
    .then(response => {
      toast.success(
        'Senha alterada com sucesso'
      )
      dispatch({
        type: CHANGE_PASSWORD_SUCCESS
      })
      history.push('/login')
    })
    .catch(error => {
      processErrorMessage(dispatch, error, CHANGE_PASSWORD_ERROR)
    })
}

