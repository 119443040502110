/* USERS */
export const GET_USERS = "GET_USERS"
export const GET_USERS_LOADING = "GET_USERS_LOADING"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

/* USERS PROFILE */
export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS"
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL"
export const API_ERROR = "API_ERROR"

export const GET_STAKEHOLDER_TYPES_SUCCESS = 'GET_STAKEHOLDER_TYPES_SUCCESS'

export const SAVE_USER_LOADING = "SAVE_USER_LOADING"

export const ADD_NEW_USER = "ADD_NEW_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_FAIL = "ADD_USER_FAIL"

export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

export const DISABLE_USER_SUCCESS = "DISABLE_USER_SUCCESS"
export const ENABLE_USER_SUCCESS = "ENABLE_USER_SUCCESS"

export const SEND_EMAIL_LOADING = "SEND_EMAIL_LOADING"
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS"

export const TOGGLE_USER_MODAL_ACTIVE = "TOGGLE_USER_MODAL_ACTIVE"


export const GET_SECTORS_SUCCESS = "GET_SECTORS_SUCCESS"
export const UPDATE_SECTORS_SUCCESS = "UPDATE_SECTORS_SUCCESS"

export const GET_USER_SECTORS_SUCCESS= "GET_USERS_SECTORS_SUCCESS"

export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS"
export const UPDATE_ROLES_SUCCESS = "UPDATE_ROLES_SUCCESS"



