import PropTypes from "prop-types";
import React, { Component } from "react";
import { Alert, Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import VLibras from '@djpfs/react-vlibras'

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// action
import { userChangePassword } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";

class ChangePasswordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    componentDidMount() {
        const { token } = this.props.match.params
        this.setState({ token })
    }

    render() {
        return (
            <React.Fragment>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/" className="text-dark">
                        <i className="bx bx-home h2" />
                    </Link>
                </div>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div className="bg-primary bg-soft">
                                        <Row>
                                            <Col className="col-7">
                                                <div className="text-primary p-4">
                                                    <h5 className="text-primary">Hora de cadastrar sua nova senha!</h5>
                                                    <p>Agora basta informar sua nova senha, repetir ela igualzinha, e pronto! Vamos te redirecionar para realizar o acesso.</p>
                                                </div>
                                            </Col>
                                            <Col className="col-5 align-self-end">
                                                <img src={profile} alt="" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div>
                                            <Link to="/">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <span className="avatar-title rounded-circle bg-light">
                                                        <img
                                                            src={logo}
                                                            alt=""
                                                            className="rounded-circle"
                                                            height="34"
                                                        />
                                                    </span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="p-2">
                                            {this.props.error && this.props.error ? (
                                                <Alert color="danger" style={{ marginTop: "13px" }}>
                                                    {this.props.error}
                                                </Alert>
                                            ) : null}

                                            <Formik
                                                enableReinitialize
                                                initialValues={{
                                                    password: "",
                                                    passwordConfirmation: "",
                                                }}
                                                validationSchema={
                                                    Yup.object().shape({
                                                        password: Yup.string()
                                                            .matches(/^(?=.*[a-zA-Z])(?=.*[0-9.,-]).{8,}$/, "A senha precisa ter pelo menos 8 caracteres contendo letras e números")
                                                            .min(8, "A senha precisa ter pelo menos 8 caracteres")
                                                            .required("Informe uma senha válida"),
                                                        passwordConfirmation: Yup.string()
                                                            .oneOf([Yup.ref('password')], 'As senhas não conferem')
                                                            .required('Confirme sua senha')
                                                    })
                                                }
                                                onSubmit={values => {
                                                    this.props.userChangePassword(this.state.token, values.password, this.props.history);
                                                }}
                                            >
                                                {({ errors, status, touched }) => (
                                                    <Form className="form-horizontal">
                                                        <div className="mb-3">
                                                            <Label for="password" className="form-label">
                                                                Nova senha
                                                            </Label>
                                                            <Field
                                                                name="password"
                                                                autoComplete="true"
                                                                type="password"
                                                                className={
                                                                    "form-control" +
                                                                    (errors.password && touched.password
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="password"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label for="passwordConfirmation" className="form-label">
                                                                Confirme sua senha
                                                            </Label>
                                                            <Field
                                                                name="passwordConfirmation"
                                                                autoComplete="true"
                                                                type="password"
                                                                className={
                                                                    "form-control" +
                                                                    (errors.passwordConfirmation && touched.passwordConfirmation
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="passwordConfirmation"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <div className="text-end">
                                                            <button
                                                                className="btn btn-primary w-md"
                                                                type="submit"
                                                                disabled={this.props.loading}
                                                            >
                                                                {this.props.loading ? "Aguarde..." : "Alterar senha"}
                                                            </button>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    <p>
                                        Volte para a{" "}
                                        <Link to="/forgot-password" className="fw-medium text-primary">
                                            Redefinição de senha
                                        </Link>{" "}
                                    </p>
                                    <p>
                                        © {new Date().getFullYear()} <a href="https://ness.dev.br" target="_blank" rel="noreferrer">ness.dev.br</a>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

ChangePasswordPage.propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool,
    history: PropTypes.object,
    userChangePassword: PropTypes.func,
}

const mapStateToProps = state => {
    const { error, loading } = state.ChangePassword
    return { error, loading }
}

export default connect(mapStateToProps, { userChangePassword })(ChangePasswordPage)

