import {
  REGISTER_USER_LOADING,
  REGISTER_USER_SUCCESSFUL,
  API_ERROR,
  GET_STAKEHOLDER_TYPES_SUCCESS
} from "./actionTypes"

const initialState = {
  error: "",
  message: null,
  loading: false,
  userData: undefined,
  stakeholderTypes: []
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER_LOADING:
      state = {
        ...state,
        error: "",
        loading: true
      }
      break
    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        error: "",
        loading: false,
        userData: action.payload
      }
      break
    case GET_STAKEHOLDER_TYPES_SUCCESS:
      state = {
        ...state,
        error: "",
        stakeholderTypes: action.payload,
      }
      break
    case API_ERROR:
      state = { 
        ...state, 
        error: action.payload, 
        loading: false 
      }
      break
    default:
      state = { ...state }
      break

  }
  return state
}

export default account
