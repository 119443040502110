import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import {  GET_USERS_LOADING, GET_USERS_SUCCESS } from "./actionTypes";

import { api } from '../../services/api'
//Include Both Helper File with needed methods
// import { getFirebaseBackend } from "../../helpers/firebase_helper";


// const fireBaseBackend = getFirebaseBackend();
import {
  getUsersSuccess,
  getUsersFail,
  getUsers,
  getUserProfileSuccess,
  getUserProfileFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
} from "./actions"

// function* fetchUsers() {
//   try {
//     const response = yield call()
//     yield put(getUsersSuccess(response.data))
//   } catch (error) {
//     yield put(getUsersFail(error))
//   }
// }

// function* fetchUserProfile() {
//   try {
//     const response = yield call(getUserProfile)
//     yield put(getUserProfileSuccess(response))
//   } catch (error) {
//     yield put(getUserProfileFail(error))
//   }
// }

// function* onAddNewUser({ payload: user }) {
//   try {
//     const response = yield call(addNewUser, user)
//     yield put(addUserSuccess(response))
//   } catch (error) {

//     yield put(addUserFail(error))
//   }
// }

// function* onUpdateUser({ payload: user }) {
//   try {
//     const response = yield call(updateUser, user)
//     yield put(updateUserSuccess(response))
//   } catch (error) {
//     yield put(updateUserFail(error))
//   }
// }

// function* onDeleteUser({ payload: user }) {
//   try {
//     const response = yield call(deleteUser, user)
//     yield put(deleteUserSuccess(response))
//   } catch (error) {
//     yield put(deleteUserFail(error))
//   }
// }

function* userListSaga() {
 // yield takeEvery(GET_USERS_LOADING, fetchUsers)
  // yield takeEvery(GET_USER_PROFILE, fetchUserProfile)
  // yield takeEvery(ADD_NEW_USER, onAddNewUser)
  // yield takeEvery(UPDATE_USER, onUpdateUser)
  // yield takeEvery(DELETE_USER, onDeleteUser)
}

// function* userListSaga() {
//     yield takeEvery(FORGET_PASSWORD, forgetUser);
//   }
  
  export default userListSaga;
