import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  EDIT_PROFILE_STARTED,
  CHANGE_PASSWORD_PROFILE,
  CHANGE_PASSWORD_PROFILE_ERROR,
  CHANGE_PASSWORD_PROFILE_SUCCESS,
  CHANGE_PASSWORD_PROFILE_STARTED,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  loading: false,
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state };
      break;
    case EDIT_PROFILE_STARTED:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case PROFILE_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        success: "Dados alterados com sucesso!",
      };
      break;
    case PROFILE_ERROR:
      state = { ...state, error: action.payload };
      break;
    case CHANGE_PASSWORD_PROFILE:
      state = { 
        ...state, 
        error: ""
      };
      break;
    case CHANGE_PASSWORD_PROFILE_STARTED:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case CHANGE_PASSWORD_PROFILE_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        success: "Senha alterada com sucesso!",
      };
      break;
    case CHANGE_PASSWORD_PROFILE_ERROR:
      state = { 
        ...state, 
        error: action.payload 
      };
      break;
    case RESET_PROFILE_FLAG:
      state = { 
        ...state, 
        error: "",
        success: "" 
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
