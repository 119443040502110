export const ROLE_ADMIN = 'admin'

export const ROLE_EMPLOYEE = 'employee'

export const ROLE_EMPLOYEE_PSY = 'employee_psy'

export const ROLE_END_USER = 'end_user'

export const ADMIN = 2

export const EMPLOYEE = 3

export const EMPLOYEE_PSY = 4

export const END_USER = 1

export const STUDENT_STAKEHOLDER_TYPE_ID = 1

export const PROFESSOR_STAKEHOLDER_TYPE_ID = 2

export const ADMNISTRATIVE_TECHNICIAN_STAKEHOLDER_TYPE_ID = 3

export const EXTERNAL_COMMUNITY_STAKEHOLDER_TYPE_ID = 4

export const CREATED = 1

export const APPROVED = 2

export const PARTIALLY_APPROVED = 3

export const REJECTED = 4

export const CANCELED_BY_REQUESTER = 5

export const currentRoleConvertion = {
    1: 'end_user',
    2: 'admin',
    3: 'employee',
    4: 'employee_psy',
  };

  export const roleNames = {
    [END_USER]: 'Solicitante',
    [ADMIN]: 'Administrador',
    [EMPLOYEE]: 'Intérprete',
    [EMPLOYEE_PSY]: 'Psicopedagogo',
  }
  
  export const currentRoleNames = {
    [ROLE_END_USER]: 'Solicitante',
    [ROLE_ADMIN]: 'Administrador',
    [ROLE_EMPLOYEE]: 'Intérprete',
    [ROLE_EMPLOYEE_PSY]: 'Psicopedagogo',
  }

export const currentRoleAction = {
    'end_user': 1,
    'admin': 2,
    'employee': 3,
    'employee_psy': 4,
};
