import React from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";

import classnames from "classnames";


import { connect } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import EventInformation from "./NewRequestTabs/EventInformation";
import { Scheduling } from "./NewRequestTabs/Scheduling";
import { AdditionalInformation } from "./NewRequestTabs/AdditionalInformation";
import ConfirmRequest from "./NewRequestTabs/ConfirmRequest";
import { useHistory } from 'react-router-dom';
import { sendEventInformation, sendDataToConfirmRequest, sendSchedules, sendRequest, getRequests, toggleSaveRequest } from '../../store/actions'

class NewRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      passedSteps: [1]
    };
    this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }

  submitEventInformation(values) {
    this.props.sendEventInformation({ ...this.props.data, ...values });
    this.toggleTab(2);
  }

  submitSchedules(values) {
    this.props.sendSchedules({ ...this.props.data, ...values });
    this.toggleTab(3);
  }

  submitAdditionalInformation(values) {
    this.props.sendDataToConfirmRequest({
      ...this.props.data,
      ...values
    });
    this.toggleTab(4)
  }

  render() {
    document.title = "Nova Solicitação | Atendimento NUPI";
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Nupi" breadcrumbItem="Solicitação" />

            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Nova Solicitação</h4>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({
                            current: this.state.activeTab === 1,
                          })}>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === 1,
                            })
                            }
                            onClick={() => {
                              this.toggleTab(1);
                            }}
                          >
                            <span className="number">1.</span>{" "}
                            Informações do evento
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: this.state.activeTab === 2,
                          })}>
                          <NavLink
                            disabled={!(this.state.passedSteps || []).includes(2)}
                            className={classnames({
                              active: this.state.activeTab === 2,
                            })}
                            onClick={() => {
                              this.toggleTab(2);
                            }}
                          >
                            <span className="number">2.</span>{" "}
                            <span>Agendamento</span>
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: this.state.activeTab === 3,
                          })}>
                          <NavLink
                            disabled={!(this.state.passedSteps || []).includes(3)}
                            className={classnames({
                              active: this.state.activeTab === 3
                            }, 'done')}
                            onClick={() => {
                              this.toggleTab(3);
                            }}
                          >
                            <span className="number">3.</span>{" "}
                            Informações adicionais
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: this.state.activeTab === 4,
                          })}>
                          <NavLink
                            disabled={!(this.state.passedSteps || []).includes(4)}
                            className={classnames({
                              active: this.state.activeTab === 4,
                            }, 'done')}
                            onClick={() => {
                              this.toggleTab(4);
                            }}
                          >
                            <span className="number">4.</span>{" "}
                            Confirme sua solicitação
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent
                        activeTab={this.state.activeTab}
                        className="body"
                      >
                        <TabPane tabId={1}>
                          <EventInformation submit={(values) => this.submitEventInformation(values)} />
                        </TabPane>
                        <TabPane tabId={2}>
                          <Scheduling submit={(values) => this.submitSchedules(values)} />
                        </TabPane>
                        <TabPane tabId={3}>
                          <AdditionalInformation submit={(values) => this.submitAdditionalInformation(values)} />
                        </TabPane>
                        <TabPane tabId={4}>
                          <ConfirmRequest />
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            this.state.activeTab === 1
                              ? "previous disabled"
                              : "previous"
                          }
                        >
                          <Link
                          aria-label = "Botão voltar"
                            to="#"
                            onClick={() => {
                              this.toggleTab(this.state.activeTab - 1);
                            }}
                          >
                            Voltar
                          </Link>
                        </li>
                        <li
                          className='next'
                        >
                          <Link
                           aria-label={this.state.activeTab === 4
                            ? "Botão Concluir"
                            : "Botão Avançar"} 
                            to="#"
                            onClick={() => {
                              if (this.state.activeTab != 4) {
                                switch (this.state.activeTab) {
                                  case 1:
                                    document.getElementById('submit-event-information').click();
                                    break
                                  case 2:
                                    document.getElementById('submit-schedules').click();
                                    break;
                                  case 3:
                                    document.getElementById('submit-additional-information').click();
                                    break;
                                  default:
                                    this.toggleTab(this.state.activeTab + 1);
                                    break;
                                }
                              } else {
                                this.props.sendRequest({
                                  ...this.props.data
                                }, this.props.history);
                              }
                            }}>
                            {this.state.activeTab === 4
                              ? "Concluir"
                              : "Avançar"}  

                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { error, loading, data, requestList, saveRequestSuccess } = state.Request
  return { error, loading, data, requestList, saveRequestSuccess }
}

export default withRouter(
  connect(mapStateToProps, { sendEventInformation, sendDataToConfirmRequest, sendSchedules, sendRequest, getRequests, toggleSaveRequest })(NewRequest)
)
