import PropTypes from "prop-types";
import React, { Component } from "react";
import { Alert, Button, Card, CardBody, Col, Container, Row, Label } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

// actions
import { editProfile, changePassword, resetProfileFlag } from "../../store/actions";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", name: "", token: "", password: "", newPassword: "", passwordConfirmation: "", };
  }

  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      this.setState({
        name: obj.name,
        email: obj.email,
        token: obj.token
      });
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props !== prevProps) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      this.setState({ name: obj.name, email: obj.email, token: obj.token });
      setTimeout(() => {
        this.props.resetProfileFlag();
      }, 3000);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="Nupi" breadcrumbItem="Perfil de Usuário" />

            <Row>
              <Col lg="12">
                {this.props.error && this.props.error ? (
                  <Alert color="danger">{this.props.error}</Alert>
                ) : null}
                {this.props.success && this.props.success ? (
                  <Alert color="success">{this.props.success}</Alert>
                ) : null}
              </Col>
            </Row>

            <h4 className="card-title mb-4">Informações pessoais</h4>

            <Card>
              <CardBody>
                <Formik
                  enableReinitialize
                  initialValues={{
                    name: (this.state && this.state.name) || "",
                    email: (this.state && this.state.email) || "",
                    token: (this.state && this.state.token) || "",
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string()
                      .required('Informe um nome de usuário válido')
                      .matches(/[A-Z][a-z].* [A-Z][a-z].*/, 'Informe o seu nome completo com iniciais maiúsculas'),
                    email: Yup.string()
                      .required('Informe o seu e-mail')
                      .matches(/\S+@\S+\.\S+/, 'Informe um email válido'),
                  })}
                  onSubmit={values => {
                    this.props.editProfile(values);
                  }}
                >
                  {({ errors, status, touched }) => (
                    <Form className="form-horizontal">
                      <div className="mb-3">
                        <Label for="name" className="form-label">
                          Nome
                        </Label>
                        <Field
                          name="name"
                          type="text"
                          className={
                            "form-control" +
                            (errors.name && touched.name ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="mb-3">
                        <Label for="email" className="form-label">
                          Email
                        </Label>
                        <Field
                          name="email"
                          type="email"
                          className={
                            "form-control" +
                            (errors.email && touched.email ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <Field
                        name="token"
                        type="hidden"
                        className={
                          "form-control" +
                          (errors.token && touched.token ? " is-invalid" : "")
                        }
                      />
                      <div className="text-center mt-4 d-flex flex-row-reverse">
                        <Button size="lg" type="submit" color="success">
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>


            <h4 className="card-title mb-4">Alteração de senha</h4>

            <Card>
              <CardBody>
                <Formik
                  enableReinitialize
                  initialValues={{
                    password: (this.state && this.state.password) || "",
                    newPassword: (this.state && this.state.newPassword) || "",
                    passwordConfirmation:
                      (this.state && this.state.passwordConfirmation) || "",
                    token: (this.state && this.state.token) || "",
                  }}
                  validationSchema={Yup.object().shape({
                    password: Yup.string()
                      .min(8, 'A senha precisa ter pelo menos 8 caracteres')
                      .required('Informe a sua senha atual'),
                    newPassword: Yup.string()
                      .matches(/^(?=.*[a-zA-Z])(?=.*[0-9.,-]).{8,}$/, 'A senha precisa ter pelo menos 8 caracteres contendo letras e números')
                      .min(8, 'A senha precisa ter pelo menos 8 caracteres')
                      .required('Informe a sua senha atual'),
                    passwordConfirmation: Yup.string()
                      .oneOf([Yup.ref('newPassword')], 'As senhas não conferem')
                      .required('Repita a senha informada'),
                  })}
                  onSubmit={values => {
                    this.props.changePassword(values);
                  }}
                >
                  {({ errors, status, touched }) => (
                    <Form className="form-horizontal">
                      <div className="mb-3">
                        <Label for="password" className="form-label">
                          Senha atual
                        </Label>
                        <Field
                          name="password"
                          type="password"
                          className={
                            "form-control" +
                            (errors.password && touched.password
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="mb-3">
                        <Label for="newPassword" className="form-label">
                          Nova senha
                        </Label>
                        <Field
                          name="newPassword"
                          type="password"
                          className={
                            "form-control" +
                            (errors.newPassword && touched.newPassword
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="newPassword"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="mb-3">
                        <Label for="passwordConfirmation" className="form-label">
                          Confirme a nova senha
                        </Label>
                        <Field
                          name="passwordConfirmation"
                          type="password"
                          className={
                            "form-control" +
                            (errors.passwordConfirmation && touched.passwordConfirmation
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="passwordConfirmation"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <Field
                        name="token"
                        type="hidden"
                        className={
                          "form-control" +
                          (errors.token && touched.token ? " is-invalid" : "")
                        }
                      />
                      <div className="text-center mt-4 d-flex flex-row-reverse">
                        <Button size="lg" type="submit" color="success">
                          Alterar senha
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  changePassword: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  resetProfileFlag: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStateToProps, { editProfile, resetProfileFlag, changePassword })(UserProfile)
);
