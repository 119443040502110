import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { IMaskInput } from 'react-imask'
import { Alert, Card, CardBody, Col, Container, Row, Label } from 'reactstrap'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { PROFESSOR_STAKEHOLDER_TYPE_ID, ADMNISTRATIVE_TECHNICIAN_STAKEHOLDER_TYPE_ID, STUDENT_STAKEHOLDER_TYPE_ID, EXTERNAL_COMMUNITY_STAKEHOLDER_TYPE_ID } from 'constants/roles'

// action
import {
  getStakeholderTypes
} from 'store/shared/actions'
import {
  registerUser
} from '../../store/actions'
import validate from 'helpers/validateCpf'

// Redux
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

// import images
import profileImg from '../../assets/images/profile-img.png'
import logoImg from '../../assets/images/logo.svg'
'use strict'
class Register extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    const { getStakeholderTypes } = this.props
    getStakeholderTypes()
  }
  render() {
    return (
      <React.Fragment>
        <div className='home-btn d-none d-sm-block'>
          <Link to='/' className='text-dark'>
            <i className='bx bx-home h2' />
          </Link>
        </div>
        <div className='account-pages my-5 pt-sm-5'>
          <Container>
            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card className='overflow-hidden'>
                  <div className='bg-primary bg-soft'>
                    <Row>
                      <Col className='col-7'>
                        <div className='text-primary p-4'>
                          <h5 className='text-primary'>Criar conta</h5>
                          <p>Se cadastre no Sistema NUPI .</p>
                        </div>
                      </Col>
                      <Col className='col-5 align-self-end'>
                        <img src={profileImg} alt='' className='img-fluid' />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className='pt-0'>
                    <div>
                      <Link to='/'>
                        <div className='avatar-md profile-user-wid mb-4'>
                          <span className='avatar-title rounded-circle bg-light'>
                            <img
                              src={logoImg}
                              alt=''
                              className='rounded-circle'
                              height='34'
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className='p-2'>
                      {
                        this.props.error && (
                          <Alert color='danger'>{this.props.error}</Alert>
                        )
                      }
                      
                      <Formik
                        enableReinitialize
                        initialValues={{
                          name: '',
                          email: '',
                          phone: '',
                          stakeholderTypeId: STUDENT_STAKEHOLDER_TYPE_ID,
                          documentNumber: '',
                          password: '',
                          passwordConfirmation: '',
                        }}
                        validationSchema={Yup.object().shape({
                          name: Yup.string()
                            .required('Informe um nome de usuário válido')
                            .matches(/[A-Z][a-z].* [A-Z][a-z].*/, 'Informe o seu nome completo com iniciais maiúsculas'),
                          email: Yup.string()
                            .required('Informe o seu e-mail')
                            .matches(/\S+@\S+\.\S+/, 'Informe um email válido'),
                          phone: Yup.string()
                            .min(13, 'O número precisa ter 10 ou 11 dígitos')
                            .max(14, 'O número precisa ter 10 ou 11 dígitos')
                            .required('Informe um telefone válido'),
                          stakeholderTypeId: Yup.number()
                            .required('Selecione um vínculo'),
                          documentNumber: Yup.string()
                            .when('stakeholderTypeId', {
                              is: (STUDENT_STAKEHOLDER_TYPE_ID),
                              then: Yup.string().required('Informe o CPF').test('is-calculation-correct', 'CPF inválido', (documentNumber) => validate(documentNumber)),
                            })
                            .when('stakeholderTypeId', {
                              is: (EXTERNAL_COMMUNITY_STAKEHOLDER_TYPE_ID),
                              then: Yup.string().required('Informe o CPF').test('is-calculation-correct', 'CPF inválido', (documentNumber) => validate(documentNumber)),
                            })
                            .when('stakeholderTypeId', {
                              is: (PROFESSOR_STAKEHOLDER_TYPE_ID),
                              then: Yup.string().required('Informe o SIAPE')
                              .min(7, 'O SIAPE precisa ter 7 dígitos'),
                            })
                            .when('stakeholderTypeId', {
                              is: (ADMNISTRATIVE_TECHNICIAN_STAKEHOLDER_TYPE_ID),
                              then: Yup.string().required('Informe o SIAPE')
                              .min(7, 'O SIAPE precisa ter 7 dígitos'),
                            }),
                          password: Yup.string()
                            .matches(/^(?=.*[a-zA-Z])(?=.*[0-9.,-]).{8,}$/, 'A senha precisa ter pelo menos 8 caracteres contendo letras e números')
                            .min(8, 'A senha precisa ter pelo menos 8 caracteres')
                            .required('Informe uma senha válida'),
                          passwordConfirmation: Yup.string()
                            .oneOf([Yup.ref('password')], 'As senhas não conferem')
                            .required('Repita a senha informada')
                        })}
                        onSubmit={values => {
                          this.props.registerUser(values, this.props.history)
                        }}

                      >
                        {({ errors, touched, values, setFieldValue }) => (
                          <Form className='form-horizontal'>
                            <div className='mb-3'>
                              <Label for='name' className='form-label'>
                                Nome completo
                              </Label>
                              <Field
                                name='name'
                                type='text'
                                id='name'
                                className={
                                  'form-control' +
                                  (errors.name && touched.name
                                    ? ' is-invalid'
                                    : '')
                                }
                              />
                              <ErrorMessage
                                name='name'
                                component='div'
                                className='invalid-feedback'
                              />
                            </div>
                            <Label for='phone' className='form-label'>
                              Telefone
                            </Label>
                            <div className='mb-3'>
                              <Field
                                as={IMaskInput}
                                name='phone'
                                mask='(00)00000-0000'
                                id='phone'
                                type='text'
                                className={
                                  'form-control' +
                                  (errors.phone && touched.phone
                                    ? ' is-invalid'
                                    : '')
                                }
                              />
                              <ErrorMessage
                                name='phone'
                                component='div'
                                className='invalid-feedback'
                              />
                            </div>
                            <div className='mb-3'>
                              <Label for='stakeholderTypeId' className='form-label'>
                                Vínculo
                              </Label>
                              <Field 
                                className='form-control' 
                                as='select' 
                                name='stakeholderTypeId'
                                id='stakeholderTypeId'
                                onChange={event => {
                                  setFieldValue('stakeholderTypeId', Number(event.currentTarget.value))
                                }}
                              >
                                {this.props.stakeholderTypes.map((stakeholderType, index) => (
                                  <option key={index} value={stakeholderType.id} >
                                    {stakeholderType.name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name='stakeholderTypeId'
                                component='div'
                                className='invalid-feedback'
                              />
                            </div>
                            {(values.stakeholderTypeId === ADMNISTRATIVE_TECHNICIAN_STAKEHOLDER_TYPE_ID || values.stakeholderTypeId === PROFESSOR_STAKEHOLDER_TYPE_ID) && (
                              <>
                                <Label for='documentNumber' className='form-label'>
                                  <div> {'SIAPE'} </div>
                                </Label>
                                <div className='mb-3'>
                                  <Field
                                    as={IMaskInput}
                                    mask={'0000000'}
                                    name={'documentNumber'}
                                    id='documentNumber'
                                    type='text'
                                    className={
                                      'form-control' +
                                      (errors.documentNumber && touched.documentNumber
                                        ? ' is-invalid'
                                        : '')
                                    }
                                  />
                                  <ErrorMessage
                                    name={'documentNumber'}
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </div>
                              </>
                            )}
                            {(values.stakeholderTypeId === STUDENT_STAKEHOLDER_TYPE_ID || values.stakeholderTypeId === EXTERNAL_COMMUNITY_STAKEHOLDER_TYPE_ID) && (
                              <>
                                <Label for='documentNumber' className='form-label'>
                                  <div> {'CPF'} </div>
                                </Label>
                                <div className='mb-3'>
                                  <Field
                                    as={IMaskInput}
                                    mask={'000.000.000-00'}
                                    name={'documentNumber'}
                                    id='documentNumber'
                                    type='text'
                                    className={
                                      'form-control' +
                                      (errors.documentNumber && touched.documentNumber
                                        ? ' is-invalid'
                                        : '')
                                    }
                                  />
                                  <ErrorMessage
                                    name={'documentNumber'}
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </div>
                              </>)}
                            <div className='mb-3'>
                              <Label for='email' className='form-label'>
                                Email
                              </Label>
                              <Field
                                name='email'
                                id='email'
                                type='email'
                                className={
                                  'form-control' +
                                  (errors.email && touched.email
                                    ? ' is-invalid'
                                    : '')
                                }
                              />
                              <ErrorMessage
                                name='email'
                                component='div'
                                className='invalid-feedback'
                              />
                            </div>
                            <div className='mb-3'>
                              <Label for='password' className='form-label'>
                                Senha
                              </Label>
                              <Field
                                name='password'
                                autoComplete='true'
                                id='password'
                                type='password'
                                className={
                                  'form-control' +
                                  (errors.password && touched.password
                                    ? ' is-invalid'
                                    : '')
                                }
                              />
                              <ErrorMessage
                                name='password'
                                component='div'
                                className='invalid-feedback'
                              />
                            </div>
                            <div className='mb-3'>
                              <Label for='passwordConfirmation' className='form-label'>
                                Confirmação de senha
                              </Label>
                              <Field
                                name='passwordConfirmation'
                                autoComplete='true'
                                id='passwordConfirmation'
                                type='password'
                                className={
                                  'form-control' +
                                  (errors.passwordConfirmation && touched.passwordConfirmation
                                    ? ' is-invalid'
                                    : '')
                                }
                              />
                              <ErrorMessage
                                name='passwordConfirmation'
                                component='div'
                                className='invalid-feedback'
                              />
                            </div>
                            <div className='mt-4 d-grid'>
                              <button
                                className='btn btn-primary btn-block'
                                aria-label='Criar conta'
                                type='submit'
                                disabled={this.props.loading}
                              >
                                {this.props.loading ? 'Aguarde...' : 'Criar conta'}
                              </button>
                            </div>

                            <div className='mt-4 text-center'>
                              <p className='mb-0'>
                                Ao se registrar, você concorda com os{' '}
                                <Link to='#' className='text-primary'>
                                  Termos de Uso
                                </Link> do Sistema NUPI
                              </p>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className='mt-5 text-center'>
                  <p>
                    Você já tem uma conta ?{' '}
                    <Link to='/login' className='fw-medium text-primary'>
                      {' '}
                      Entrar
                    </Link>{' '}
                  </p>
                  <p>
                    © {new Date().getFullYear()} <a href="https://ness.dev.br" target="_blank" rel="noreferrer">ness.dev.br</a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment >
    )
  }

}


Register.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  history: PropTypes.object,
  user: PropTypes.object,
  registerUser: PropTypes.func,
  getStakeholderTypes: PropTypes.func,
  stakeholderTypes: PropTypes.array
}


const mapStateToProps = state => {
  const { user, error, loading } = state.Account
  const { stakeholderTypes } = state.shared
  return { user, error, loading, stakeholderTypes }
}

export default withRouter(connect(mapStateToProps, {
  registerUser, getStakeholderTypes
})(Register))
