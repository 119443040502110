import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from "lodash";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import CancelEventModal from 'components/Common/CancelEventModal';
import RestartEventModal from 'components/Common/RestartEventModal';
import moment from 'moment';
import ChangeInterpreterModal from './changeInterpreterModal';
import { toggleChangeInterpreter } from 'store/appointments/actions';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Label,
  UncontrolledTooltip,
  Table,
  Input,

} from 'reactstrap'

import Breadcrumbs from 'components/Common/Breadcrumb'
import { format } from 'date-fns';


import { formatTypes, statusTypes } from 'constants/statusFormat';
import {
  getEventsList,
  cancelSchedule,
  restartSchedule
} from "store/appointments/actions";
import { getInterpretersList } from 'store/appointments/actions';
import { change } from 'redux-form';


class AppointmentsList extends Component {
  constructor(props) {
    super(props)
    this.node = React.createRef()
    this.state = {
      total: 0,
      pages: [],
      sectors: [],
      page: 1,
      event: {},
      eventsList: [],
      startDate: "",
      endDate: "",
      modal: false,
      modalCancel: false,
      selectedEventId: null,
      modalType: null,
      changeInterpreterModalVisible: false,
      selectedEvent: null,
    }
    this.toggleRestartModal = this.toggleRestartModal.bind(this);
    this.toggleCancel = this.toggleCancel.bind(this);
    this.toggleChangeInterpreterModal = this.toggleChangeInterpreterModal.bind(this);
    this.handleRestart = this.handleRestart.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    const { eventsList, onGetEvents } = this.props
    if (eventsList && !eventsList.length) {
      onGetEvents(this.state.page, this.state.startDate, this.state.endDate)
    }
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    const { currentPage: page, totalCount, eventsList, onGetEvents, } = this.props
    if (!eventsList) {
      onGetEvents(this.state.page, this.state.startDate, this.state.endDate)
    }
    if (prevProps.totalCount !== this.props.totalCount || prevProps.eventsList !== this.props.eventsList) {
      const totalPages = Math.ceil(totalCount / 10)
      const arrayPages = []
      for (let i = 1; i <= totalPages; i++) {
        arrayPages.push(i)
      }
      this.setState({ eventsList: eventsList, pages: arrayPages, total: totalCount });
    }

    if (prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate || prevState.page !== this.state.page) {
      onGetEvents(this.state.page, this.state.startDate, this.state.endDate)
    }

    if (this.props.interpreters !== prevProps.interpreters) {
      this.setState({
        interpretersList: this.props.interpreters,
        loading: false,
      });
    }
  }
  onCancelSchedule = (id) => {
    this.props.onCancelSchedule(id)
  }

  onRestartSchedule = (id) => {
    this.props.onRestartSchedule(id)
  }
  toggleRestartModal(id = null) {
    this.setState(prevState => ({
      modal: !prevState.modal,
      selectedEventId: id,
    }));
  }

  handleRestartWithEvent(event) {
    this.toggleRestartModal(event.id);
  }

  handleRestart() {
    this.toggleRestartModal();
    this.props.onRestartSchedule(this.state.selectedEventId);
  }

  toggleCancel(id) {
    this.setState(prevState => ({
      modalCancel: !prevState.modalCancel,
      selectedEventId: id,
    }));
  }

  toggleChangeInterpreterModal(event = null) {
    if (event && event.scheduleConfirmations && event.scheduleConfirmations.attendanceRequest && event !== '') {
      this.props.onGetInterpreters(event.scheduleConfirmations.attendanceRequest.id);
      this.setState(prevState => ({
        selectedEvent: event,
      }));
    }
    this.props.toggleChangeInterpreter();
  }

  toggleChangeInterpreterModalWithEvent = event => () => {
    this.toggleChangeInterpreterModal(event);
  }

  handleCancelWithEvent(event) {
    this.toggleCancel(event.id);
  }

  handleCancel() {
    this.toggleCancel();
    this.props.onCancelSchedule(this.state.selectedEventId);
  }

  handleEditAppointment = arg => {
    const event = arg;
    this.setState({
      event: {
        id: event.interpreters.id,
        name: event.name,
        phone: event.phone,
        stakeholderTypeId: event.stakeholderTypeId,
        documentNumber: event.documentNumber,
        role: event.role,
        email: event.email,
        deletedAt: event.deletedAt,
        sectors: event.userSectors,
      },
      isEdit: true,
    });
  }

  render() {
    document.title = 'Compromissos Marcados | Atendimento NUPI'
    const { totalCount, history, eventsList, loading } = this.props

    const totalPages = Math.ceil(totalCount / 10)
    const arrayPages = []
    for (let i = 1; i <= totalPages; i++) {
      arrayPages.push(i)
    }
    if (!eventsList) {
      return null;
    }
    return (
      <React.Fragment>
        <div className='page-content'>
          <Container fluid>
            <Breadcrumbs title='Tabelas' breadcrumbItem='Compromissos marcados' />
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg="12">
                        <Row>
                          <Col md="6" className="align-items-baseline">
                            <Label for="startDate" className="form-label">Data inicial</Label>
                            <div>
                              <Input type="date" name="startDate" id="startDate" style={{
                                width: '100%',
                                padding: '8px',
                                boxSizing: 'border-box',
                                border: '1px solid #ced4da',
                                borderRadius: '5px',
                                fontSize: '14px'
                              }} onChange={e => this.setState({ startDate: e.target.value })} />
                            </div>
                          </Col>
                          <Col md="6" className="align-items-baseline">
                            <Label for="endDate" className="form-label">Data final</Label>
                            <div>
                              <Input type="date" name="endDate" id="endDate" style={{
                                width: '100%',
                                padding: '8px',
                                boxSizing: 'border-box',
                                border: '1px solid #ced4da',
                                borderRadius: '5px',
                                fontSize: '14px'
                              }} onChange={e => this.setState({ endDate: e.target.value })} />
                            </div>
                          </Col>
                        </Row>
                        <Table responsive>
                        </Table>
                      </Col>
                    </Row>

                    <Table responsive>
                      <thead className='text-center border-bottom'>
                        <tr>
                          <th>Solicitação</th>
                          <th>Data</th>
                          <th>Status</th>
                          <th>Interprete</th>
                          <th>Setor</th>
                          <th>Formato</th>
                          <th>Ação</th>
                        </tr>
                      </thead>
                      <tbody className='text-center'>
                        {this.props.eventsList.map(event => (
                          <tr key={event.id}>
                            <td>{event.scheduleConfirmations.attendanceRequest.eventName}</td>
                            <td>{moment.utc(event.scheduleConfirmations.date).format('DD/MM/YYYY HH:mm')}</td>
                            <td>{statusTypes[event.scheduleConfirmations.status] || event.scheduleConfirmations.status}</td>
                            <td>{event.interpreters.name}</td>
                            <td>{event.scheduleConfirmations.attendanceRequest.sector.sector}</td>
                            <td>{formatTypes[event.scheduleConfirmations.attendanceRequest.format] || event.scheduleConfirmations.attendanceRequest.format}</td>
                            <td>
                              <div>
                                {event.scheduleConfirmations.status === 1 && (
                                  <div>
                                    <Link
                                      className='text-success'
                                      to='#'
                                      id={'changeInterpreter' + event.id}
                                      onClick={() => this.toggleChangeInterpreterModal(event)}
                                    >
                                      <i className='mdi mdi-pencil font-size-18' aria-label={'Mudar intérprete para ' + event.title}></i>
                                      <UncontrolledTooltip placement='top' target={'changeInterpreter' + event.id}>Mudar intérprete</UncontrolledTooltip>
                                    </Link>
                                    <Link
                                      className='text-danger'
                                      to='#'
                                      id={'messageBlock' + event.id}
                                      style={{ marginLeft: '10%' }}
                                      onClick={() => this.handleCancelWithEvent(event)}
                                    >
                                      <i className='mdi mdi-block-helper font-size-18' id='deletetooltip'></i>
                                      <UncontrolledTooltip placement='top' target={'messageBlock' + event.id}>Cancelar compromisso</UncontrolledTooltip>
                                    </Link>
                                  </div>
                                )}

                                {event.scheduleConfirmations.status === 2 && (
                                  <Link
                                    to='#'
                                    id={'messageBlock' + event.id}
                                    onClick={() => this.handleRestartWithEvent(event)}
                                  >
                                    <i className={'mdi mdi-restart font-size-24 '} style={{ color: 'blue' }} id='restarttooltip'></i>
                                    <UncontrolledTooltip placement='top' target={'messageBlock' + event.id}>Reiniciar compromisso</UncontrolledTooltip>
                                  </Link>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>

                    </Table>
                    <div className='d-flex flex-row-reverse bd-highlight'>
                      <div className='p-2 bd-highlight'>
                        {this.props.totalCount > 10 ? (
                          <React.Fragment>
                            <button
                              disabled={loading}
                              onClick={() => {
                                this.setState({ page: 1 });
                              }}
                              className='btn btn-primary mx-1 '
                              style={{ backgroundColor: 'transparent', border: 'none', color: this.state.page === 1 ? 'lightgray' : '#556ee6' }}
                            >
                              <div style={{ fontSize: '200%' }}> {'<<'} </div>
                            </button>
                            <button
                              disabled={loading || this.state.page === 1}
                              onClick={() => {
                                this.setState({ page: this.state.page - 1 });
                              }}
                              className='btn btn-primary mx-1'
                              style={{ borderRadius: '50%', backgroundColor: 'transparent', border: 'none', color: this.state.page === 1 ? 'lightgray' : '#556ee6' }}
                            >
                              <div style={{ fontSize: '200%' }}> {'<'}</div>
                            </button>
                            {(() => {
                              let filteredPages = [];
                              arrayPages.forEach(page => {
                                if (page - 1 === this.state.page || page + 1 === this.state.page || page === this.state.page) {
                                  filteredPages.push(page);
                                }
                              });
                              return filteredPages.map(page => (
                                <button
                                  disabled={loading || page === this.state.page}
                                  key={page}
                                  onClick={() => {
                                    this.setState({ page: page });
                                  }}
                                  className='btn btn-primary mx-1 rounded-circle'
                                >
                                  {page}
                                </button>
                              ));
                            })()}
                            <button
                              disabled={loading || this.state.page === this.state.pages.length}
                              onClick={() => {
                                this.setState({ page: this.state.page + 1 });
                              }}
                              className='btn btn-primary mx-1'
                              style={{ borderRadius: '50%', backgroundColor: 'transparent', border: 'none', color: this.state.page === this.state.pages.length ? 'lightgray' : '#556ee6' }}
                            >
                              <div style={{ fontSize: '200%' }}> {'>'}</div>
                            </button>
                            <button
                              disabled={loading}
                              onClick={() => {
                                this.setState({ page: this.state.pages.length });
                              }}
                              className='btn btn-primary mx-1 '
                              style={{ backgroundColor: 'transparent', border: 'none', border: 'none', color: this.state.page === this.state.pages.length ? 'lightgray' : '#556ee6' }}
                            >
                              <div style={{ fontSize: '200%' }}> {'>>'} </div>
                            </button>
                          </React.Fragment>
                        ) : ''}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div >
        <ChangeInterpreterModal
          event={this.state.selectedEvent}
          isOpen={this.props.changeInterpreterModal}
          toggle={this.toggleChangeInterpreterModal}
          interpreters={this.props.interpretersList}
        />
        <CancelEventModal
          showModal={this.state.modalCancel}
          onCloseClick={this.toggleCancel}
          onDeleteClick={this.handleCancel}
        />
        <RestartEventModal
          show={this.state.modal}
          onCloseClick={this.toggleRestartModal}
          onRestartClick={this.handleRestart}
        />
      </React.Fragment >
    )
  }
}

AppointmentsList.propTypes = {
  errorMessage: PropTypes.any,
  attendanceRequests: PropTypes.array,
  onGetCanceledRequests: PropTypes.func,
  onExportCanceledRequests: PropTypes.func,
  eventsList: PropTypes.array,
  onGetEvents: PropTypes.func,
  onGetInterpreters: PropTypes.func,
  event: PropTypes.object,
}



const mapStateToProps = (state, shared) => ({
  eventsList: state.appointmentsList.eventsList,
  event: state.appointmentsList.event,
  errorMessage: state.appointmentsList.error,
  currentPage: state.appointmentsList.currentPage,
  totalCount: state.appointmentsList.totalCount,
  pageCount: state.appointmentsList.pageCount,
  page: state.appointmentsList.page,
  loading: state.appointmentsList.loading,
  interpreters: state.appointmentsList.interpretersList,
  changeInterpreterModal: state.appointmentsList.changeInterpreterModal,

})


const mapDispatchToProps = dispatch => ({
  onRestartSchedule: (id) => dispatch(restartSchedule(id)),
  onCancelSchedule: (id) => dispatch(cancelSchedule(id)),
  onGetInterpreters: requestId => dispatch(getInterpretersList(requestId)),
  toggleChangeInterpreter: () => dispatch(toggleChangeInterpreter()),
  onGetEvents: (page = 1, dateIntervalStart, dateIntervalEnd) =>
    dispatch(
      getEventsList({
        page,
        dateIntervalStart,
        dateIntervalEnd,
      })
    ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppointmentsList)
)