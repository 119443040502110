import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
} from "reactstrap";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
//css
import "@fullcalendar/bootstrap/main.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getEvents, getMeetingDetails, closeModal } from "../../store/actions";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";

class Calender extends Component {
  constructor(props) {
    super(props);
    this.calendarComponentRef = React.createRef();
    this.state = {
      modal: false,
      event: {},
    };
    this.toggle = this.toggle.bind(this);
    this.colorsForEvents = {};
  }

  handleDatesSet = (info) => {
    const view = info.view;
    const dateIntervalStart = view.activeStart;
    const dateIntervalEnd = view.activeEnd;
    const { onGetEvents } = this.props;
    onGetEvents(dateIntervalStart, dateIntervalEnd);
  };


  componentDidUpdate(prevProps, prevState, snapshot) {
    const { event } = this.props;
    const { modal } = this.state;

    if (prevState.modal !== modal && !modal && !isEmpty(event)) {
      setTimeout(() => {
        this.setState({ event: {} });
      }, 500);
    }

    if (prevState.event !== event && !isEmpty(event)) {
      this.setState({ event: event });
    }
  }

  toggle(selectedMeeting) {
    if (this.props.event == null) {
      const { onGetMeetingDetails } = this.props;
      onGetMeetingDetails(selectedMeeting.id);
    } else {
      const { onCloseModal } = this.props;
      onCloseModal();
    }
  }

  handleEventClick = (arg) => {
    const selectedMeeting = arg.event;
    this.toggle(selectedMeeting);
  };



  render() {
    document.title = "Meus compromissos | Atendimento NUPI";
    const { event } = this.state;
    const repeat = {
      weekly: "Semanal",
      monthly: "Mensal",
      daily: "Diário",
      "not-repeat": "Não",
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Atendimento" breadcrumbItem="Meus compromissos" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-12">
                        <FullCalendar
                          ref={this.calendarComponentRef}
                          plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                          slotDuration={"00:15:00"}
                          handleWindowResize
                          
                          themeSystem="bootstrap"
                          locale={ptBrLocale}
                          eventTimeFormat={{
                            hour: "2-digit",
                            minute: "2-digit",
                            meridiem: false,
                          }}
                          timeZone="UTC"
                          headerToolbar={{
                            left: "prev,next today",
                            center: "title",
                            right: "dayGridMonth,dayGridWeek,dayGridDay",
                          }}
                          buttonText={{
                            prevYear: '<span aria-label="Ano anterior"><<</span>',
                            nextYear: '<span aria-label="Próximo ano">>></span>'
                          }}
                          events={this.props.events}
                          editable={false}
                          selectable
                          eventClick={this.handleEventClick}
                          datesSet={this.handleDatesSet}
                        />

                        <Modal
                          isOpen={this.props.event != null}
                          className={this.props.className}
                          id="event-modal"
                        >
                          <ModalHeader toggle={this.toggle} tag="h4" role="text">
                            {event.scheduleConfirmations?.attendanceRequest?.eventName}
                          </ModalHeader>
                          <ModalBody>
                            {event.scheduleConfirmations && (
                              <>
                                <Row>
                                  <Col xs={12}>
                                    <div className="mb-3 ">
                                      <h5 aria-level="0" role="presentation" aria-label={`Tipo da atividade ${event.scheduleConfirmations.attendanceRequest.activity}`}>
                                        <b>Atividade:</b>{" "}
                                        {event.scheduleConfirmations.attendanceRequest.activity}
                                      </h5>
                                      <h5 aria-level="0" role="presentation" aria-label={`Com formato ${event.scheduleConfirmations.attendanceRequest.format ===
                                        "presential"
                                        ? "Presencial"
                                        : "Remoto"}`}>
                                        <b>Formato:</b>{" "}
                                        {event.scheduleConfirmations.attendanceRequest.format ===
                                          "presential"
                                          ? "Presencial"
                                          : "Remoto"}
                                      </h5>
                                      <h5 aria-level="0" role="presentation" aria-label={`do Setor ${event.scheduleConfirmations.attendanceRequest.sector.sector}`}>
                                        <b>Setor:</b>{" "}
                                        {event.scheduleConfirmations.attendanceRequest.sector.sector}
                                      </h5>
                                      <h5 aria-level="0" role="presentation" aria-label={event.scheduleConfirmations.attendanceRequest.format ===
                                        "presential"
                                        ? `Local da atividade ${event.scheduleConfirmations.attendanceRequest.address}`
                                        : `Link da atividade ${event.scheduleConfirmations.attendanceRequest.address}`}>
                                        <b>
                                          {event.scheduleConfirmations.attendanceRequest.format ===
                                            "presential"
                                            ? "Local da atividade"
                                            : "Link da atividade"}
                                        </b>{" "}
                                        {event.scheduleConfirmations.attendanceRequest.address}
                                      </h5>
                                      <h5 aria-level="0" role="presentation" aria-label={event.scheduleConfirmations.attendanceRequest.musicalPresentation
                                        ? "Haverá apresentação musical"
                                        : "Não haverá apresentação musical"}>
                                        <b>Apresentação musical:</b>{" "}
                                        {event.scheduleConfirmations.attendanceRequest.musicalPresentation
                                          ? "Sim"
                                          : "Não"}
                                      </h5>
                                      <h5 aria-level="0" role="presentation" aria-label={event.scheduleConfirmations.attendanceRequest.musicalPresentation
                                        ? "Haverá hino nacional"
                                        : "Não haverá hino nancional"}>
                                        <b>Hino nacional:</b>{" "}
                                        {event.scheduleConfirmations.attendanceRequest.nationalAnthem
                                          ? "Sim"
                                          : "Não"}
                                      </h5>
                                      <h5 aria-level="0" role="presentation" aria-label={`Repetição ${repeat[event.scheduleConfirmations.schedule.repeat]}`}>
                                        <b>Repetição:</b>{" "}
                                        {repeat[event.scheduleConfirmations.schedule.repeat]}
                                      </h5>
                                      <h5 aria-level="0" role="presentation" aria-label={`Observações ${event.scheduleConfirmations.attendanceRequest.additionalRemarks}`}>
                                        <b>Observações:</b>{" "}
                                        {event.scheduleConfirmations.attendanceRequest.additionalRemarks}
                                      </h5>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="text-end">
                                      <button
                                        type="button"
                                        className="btn btn-light me-2"
                                        onClick={this.toggle}
                                      >
                                        Fechar
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </ModalBody>
                        </Modal>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Calender.propTypes = {
  className: PropTypes.string,
  events: PropTypes.array,
  onGetEvents: PropTypes.func,
  event: PropTypes.object,
};

const mapStateToProps = ({ calendar }) => ({
  events: calendar.events,
  event: calendar.event,
});

const mapDispatchToProps = (dispatch) => ({
  onGetEvents: (dateIntervalStart, dateIntervalEnd) =>
    dispatch(
      getEvents({
        dateIntervalStart,
        dateIntervalEnd,
      })
    ),
  onGetMeetingDetails: (meetingId) => dispatch(getMeetingDetails(meetingId)),
  onCloseModal: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Calender);
