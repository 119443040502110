import { toast } from 'react-toastify'
export const processErrorMessage = (dispatch, error, type, withNotification = false) => {
    const message = (
        error
        && error.response 
        && error.response.data.errors 
        && error.response.data.errors[0].message
    ) || 'Parece que deu PT... Entre em contato com o NUPI e te ajudaremos com este problema.'
    if (withNotification) {
        toast.error(
            message,
            'Oops!'
        )
    }
    dispatch({
        type,
        payload: `Oops! ${message}`,
    })
}