import {
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false
}

const changePassword = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_LOADING:
      return {
        ...state,
        error: "",
        loading: true
      }
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false
      }
    case CHANGE_PASSWORD_ERROR:
      return { 
        ...state,
        error: action.payload,
        loading: false
      }
    default:
      return { ...state }
  }
}

export default changePassword
