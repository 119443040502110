const activityTypes = [
    'Administrativo',
    'Curso de extensão',
    'Ensino (Graduação)',
    'Ensino (Pós-graduação)',
    'Evento de extensão',
    'Gravação de vídeos',
    'PIBID',
    'Outros'
]

export default activityTypes