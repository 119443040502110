import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";


class StackedColumnChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "15%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: this.props.xAxisCategories || [], // Utiliza as categorias personalizadas do eixo x
        },
        yaxis:{
          decimalsInFloat: undefined,
          labels:{
            formatter: (value) => {
              return Math.floor(value);
            }
          }
        },
        colors: ["#556ee6", "#f1b44c", "#34c38f"],
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
    };
  }

  render() {
    return (
      <React.Fragment>
        <ReactApexChart
          options={{
            ...this.state.options,
             xaxis: {
            categories: this.props.xAxisCategories, 
          }  }}
          series={this.props.chartSeries || []}
          type="bar"
          height="360"
          className="apex-charts"
        />
      </React.Fragment>
    );
  }
}

StackedColumnChart.propTypes = {
  chartSeries: PropTypes.any,
  periodData: PropTypes.any,
  xAxisCategories: PropTypes.array, // Prop para receber as categorias personalizadas do eixo x
};

export default StackedColumnChart;
