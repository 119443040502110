import usFlag from "../assets/images/flags/us.jpg";
import ptFlag from "../assets/images/flags/brazil.png";

const languages = {
  en: {
    label: "English",
    flag: usFlag,
  },
  pt: {
    label: "Português",
    flag: ptFlag,
  },
}

export default languages;
