import React from 'react'
import {
    Card,
    CardBody,
    Col,
    Label,
    Row,
    Button
} from "reactstrap";
import moment from 'moment';

import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from 'yup'


export class Scheduling extends React.Component {
    constructor(props) {
        super(props);
    }

    schedule = {
        startDate: '',
        startTime: '',
        endTime: '',
        repeat: 'not-repeat',
        endDate: '',
        interval: false,
        startInterval: '',
        endInterval: '',
    }

    render() {
        return (
            <Formik
                enableReinitialize
                initialValues={{
                    schedules: [this.schedule]
                }}
                onSubmit={values => {
                    this.props.submit(values)
                }}
                validationSchema={
                    Yup.object().shape({
                        schedules: Yup.array().of(
                            Yup.object().shape({
                                startDate: Yup.date().required('Informe uma data')
                                    .test('startDate', 'A data dever ser maior que a atual', value => {
                                        return moment(value).isAfter(moment())
                                    }),
                                startTime: Yup.string().matches(/(\d){2}:(\d){2}/, 'Siga o padrão "00:00"').required('Informe a hora de início'),
                                endTime: Yup.string()
                                    .matches(/(\d){2}:(\d){2}/, 'Siga o padrão "00:00"')
                                    .required('Informe a hora de termino')
                                    .test('endTime', 'A hora de término deve ser maior que a hora de início', function (value) {
                                        return moment(value, 'HH:mm').isAfter(moment(this.parent.startTime, 'HH:mm'))
                                    }),
                                repeat: Yup.string().required('Informe a repetição'),
                                endDate: Yup.date().when('repeat', {
                                    is: (val) => val !== 'not-repeat',
                                    then: Yup.date()
                                        .required('Informe a data de término')
                                        .test('endDate', 'A data de término deve ser maior que a data de início', function (value) {
                                            return moment(value).isAfter(moment(this.parent.startDate))
                                        }),
                                }),
                                interval: Yup.boolean().required('Informe se haverá intervalo'),
                                startInterval: Yup.string().when('interval', {
                                    is: true,
                                    then: Yup.string().matches(/(\d){2}:(\d){2}/, 'Siga o padrão "00:00"')
                                        .required('Informe a hora de início do intervalo')
                                        .test('startInterval', 'A hora de início do intervalo deve estar entre o inicio e fim do evento', function (value) {
                                            return moment(value, 'HH:mm').isBetween(moment(this.parent.startTime, 'HH:mm'), moment(this.parent.endTime, 'HH:mm'))
                                        })
                                }),
                                endInterval: Yup.string().when('interval', {
                                    is: true,
                                    then: Yup.string().matches(/(\d){2}:(\d){2}/, 'Siga o padrão "00:00"')
                                        .required('Informe a hora de término do intervalo')
                                        .test('endInterval', 'A hora de término do intervalo deve ser maior que a hora de início do intervalo', function (value) {
                                            return moment(value, 'HH:mm').isAfter(moment(this.parent.startInterval, 'HH:mm'))
                                        })
                                        .test('startInterval', 'A hora de término do intervalo deve estar entre o inicio e fim do evento', function (value) {
                                            return moment(value, 'HH:mm').isBetween(moment(this.parent.startTime, 'HH:mm'), moment(this.parent.endTime, 'HH:mm'))
                                        })
                                }),
                            })
                        )
                    })
                }
            >
                {({ values, errors, status, touched }) => (
                    <Col>
                        <Form className='form-horizontal'>
                            <FieldArray name="schedules">
                                {({ push, remove }) => (
                                    <>
                                        {values.schedules.map((schedule, index) => (
                                            <Card key={index} outline color="primary" className="border border-primary mb-2">
                                                <CardBody>
                                                    <Row className='mb-3'>
                                                        <Col lg="6">
                                                            <Label for={`schedules.${index}.startDate`} className='form-label'>
                                                                Data
                                                            </Label>
                                                            <Field
                                                                type="date"
                                                                min={new Date().toISOString()}
                                                                name={`schedules.${index}.startDate`}
                                                                id={`schedules.${index}.startDate`}
                                                                className={
                                                                    'form-control' +
                                                                    ((errors.schedules?.length > index && errors.schedules[index]?.startDate)
                                                                        && (touched.schedules?.length > index && touched.schedules[index]?.startDate)
                                                                        ? ' is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name={`schedules.${index}.startDate`}
                                                                component='div'
                                                                className='invalid-feedback'
                                                            />
                                                        </Col>
                                                        <Col lg="3">
                                                            <Label for={`schedules.${index}.startTime`}>Início</Label>
                                                            <Field
                                                                type="time"
                                                                id={`schedules.${index}.startTime`}
                                                                name={`schedules.${index}.startTime`}
                                                                className={
                                                                    'form-control' +
                                                                    ((errors.schedules?.length > index && errors.schedules[index]?.startTime)
                                                                        && (touched.schedules?.length > index && touched.schedules[index]?.startTime)
                                                                        ? ' is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name={`schedules.${index}.startTime`}
                                                                component='div'
                                                                className='invalid-feedback'
                                                            />
                                                        </Col>
                                                        <Col lg="3">
                                                            <Label for={`schedules.${index}.endTime`}>Término</Label>
                                                            <Field
                                                                type="time"
                                                                id={`schedules.${index}.endTime`}
                                                                name={`schedules.${index}.endTime`}
                                                                className={
                                                                    'form-control' +
                                                                    ((errors.schedules?.length > index && errors.schedules[index]?.endTime)
                                                                        && (touched.schedules?.length > index && touched.schedules[index]?.endTime)
                                                                        ? ' is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name={`schedules.${index}.endTime`}
                                                                component='div'
                                                                className='invalid-feedback'
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <div className='mb-3'>
                                                        <h6 className="font-size-13 font-weight-500">Repetição:</h6>
                                                        <Col className="form-check">
                                                            <Label
                                                                className="form-check-label"
                                                                for={`schedules.${index}.not-repeat`}
                                                            >
                                                                <Field
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name={`schedules.${index}.repeat`}
                                                                    id={`schedules.${index}.not-repeat`}
                                                                    value="not-repeat"
                                                                />
                                                                <span style={{ marginLeft: '5px' }}>Não se repete</span>
                                                            </Label>
                                                        </Col>
                                                        <Col className="form-check">
                                                            <Label
                                                                className="form-check-label"
                                                                for={`schedules.${index}.daily`}
                                                            >
                                                                <Field
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name={`schedules.${index}.repeat`}
                                                                    id={`schedules.${index}.daily`}
                                                                    value="daily"
                                                                />
                                                                <span style={{ marginLeft: '5px' }}>Diariamente</span>
                                                            </Label>
                                                        </Col>
                                                        <Col className="form-check">
                                                            <Label
                                                                className="form-check-label"
                                                                for={`schedules.${index}.weekly`}
                                                            >
                                                                <Field
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name={`schedules.${index}.repeat`}
                                                                    id={`schedules.${index}.weekly`}
                                                                    value="weekly"
                                                                />
                                                                <span style={{ marginLeft: '5px' }}>Semanalmente</span>
                                                            </Label>
                                                        </Col>
                                                        <Col className="form-check">
                                                            <Label
                                                                className="form-check-label"
                                                                for={`schedules.${index}.monthly`}
                                                            >
                                                                <Field
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name={`schedules.${index}.repeat`}
                                                                    id={`schedules.${index}.monthly`}
                                                                    value="monthly"
                                                                />
                                                                <span style={{ marginLeft: '5px' }}>Mensalmente</span>
                                                            </Label>
                                                        </Col>
                                                    </div>
                                                    {schedule.repeat !== "not-repeat" &&
                                                        (<div className='mb-3' >
                                                            <Col lg="6">
                                                                <Label for={`schedules.${index}.endDate`}>
                                                                    Encerra em:
                                                                </Label>
                                                                <Field
                                                                    type="date"
                                                                    id={`schedules.${index}.endDate`}
                                                                    name={`schedules.${index}.endDate`}
                                                                    className={
                                                                        'form-control' +
                                                                        ((errors.schedules?.length > index && errors.schedules[index]?.endDate)
                                                                            && (touched.schedules?.length > index && touched.schedules[index]?.endDate)
                                                                            ? ' is-invalid'
                                                                            : '')
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name={`schedules.${index}.endDate`}
                                                                    component='div'
                                                                    className='invalid-feedback'
                                                                />
                                                            </Col>
                                                        </div>
                                                        )}
                                                    <div className='mb-3' >
                                                        <Col className="form-check form-switch" dir="ltr">
                                                            <Field
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id={`schedules.${index}.interval`}
                                                                name={`schedules.${index}.interval`}
                                                            />
                                                            <Label className="form-check-label" for={`schedules.${index}.interval`}>
                                                                Esta atividade possui intervalo?
                                                            </Label>
                                                        </Col>
                                                    </div>
                                                    {schedule.interval && (
                                                        <Row className='mb-3'>
                                                            <Col lg="3">
                                                                <Label for={`schedules.${index}.startInterval`}>Início</Label>
                                                                <Field
                                                                    type="time"
                                                                    id={`schedules.${index}.startInterval`}
                                                                    name={`schedules.${index}.startInterval`}
                                                                    className={
                                                                        'form-control' +
                                                                        ((errors.schedules?.length > index && errors.schedules[index]?.startInterval)
                                                                            && (touched.schedules?.length > index && touched.schedules[index]?.startInterval)
                                                                            ? ' is-invalid'
                                                                            : '')
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name={`schedules.${index}.startInterval`}
                                                                    component='div'
                                                                    className='invalid-feedback'
                                                                />
                                                            </Col>
                                                            <Col lg="3">
                                                                <Label for={`schedules.${index}.endInterval`}>Término</Label>
                                                                <Field
                                                                    type="time"
                                                                    id={`schedules.${index}.endInterval`}
                                                                    name={`schedules.${index}.endInterval`}
                                                                    className={
                                                                        'form-control' +
                                                                        ((errors.schedules?.length > index && errors.schedules[index]?.endInterval)
                                                                            && (touched.schedules?.length > index && touched.schedules[index]?.endInterval)
                                                                            ? ' is-invalid'
                                                                            : '')
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name={`schedules.${index}.endInterval`}
                                                                    component='div'
                                                                    className='invalid-feedback'
                                                                />
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </CardBody>
                                            </Card>
                                        ))}{" "}
                                        <Col className="d-flex flex-row-reverse mb-3">
                                            <Button
                                                type="button"
                                                color="secondary"
                                                className="btn-sm"
                                                onClick={() => push(this.schedule)}
                                            >
                                                <i className="bx bx-plus font-size-16 align-middle me-2"></i>
                                                Novo agendamento
                                            </Button>
                                        </Col>
                                    </>
                                )}
                            </FieldArray>
                            <button
                                className='d-none'
                                id='submit-schedules'
                                type='submit'
                            ></button>
                        </Form>
                    </Col>
                )
                }
            </Formik>
        );
    }
}