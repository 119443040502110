import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  FormGroup,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

import { Formik, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import { getInterpreters, getScedulesList } from "store/shared/actions";

import { acceptRequest } from "store/requests/actions";
import { ScheduleConfirm } from "./NewRequestTabs/components/ScheduleConfirm";
import { toast } from "react-toastify";
import { formatDateTime } from "helpers/date";
import CustomSelect from "./NewRequestTabs/components/MutiSelect";

class RequestConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestId: undefined,
    };
  }

  componentDidMount() {
    const { onGetSchedulesList, onGetInterpreters } = this.props;
    const requestId = this.props.location.pathname.split("/")[2];
    this.setState({ ...this.state, requestId });
    onGetSchedulesList(requestId);
    onGetInterpreters(requestId);
  }

  handleSubmit(values) {
    const { onAcceptRequest } = this.props;
    const { requestId } = this.state;
    const { attendanceRequestItems, reason } = values;

    let noItemSelected = true;
    let confirmedItemsWithouInterpreter = false;
    let allItemsSelected = true;
    let selectedItems = [];
    attendanceRequestItems.forEach(item => {
      item.scheduleConfirmations.forEach(schedule => {
        if (schedule.checked) {
          noItemSelected = false;
          selectedItems.push(schedule);
        } else {
          allItemsSelected = false;
        }
        if (schedule.checked && schedule.interpreters.length < 1) {
          confirmedItemsWithouInterpreter = true;
        }
      });
    });

    if (noItemSelected) {
      toast.error("Selecione pelo menos um compromisso");
      return;
    }

    if (confirmedItemsWithouInterpreter) {
      toast.error("Selecione pelo menos um intérprete para cada compromisso");
      return;
    }

    if (!allItemsSelected && !reason) {
      toast.error("Informe o motivo do aceite parcial");
      return;
    }

    onAcceptRequest(
      {
        schedules: selectedItems,
        reason,
        requestId,
      },
      this.props.history
    );
  }

  render() {
    const { error, success, attendanceRequestItems, interpreters } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumb title="Nupi" breadcrumbItem="Solicitação" />
            <Row>
              <Col lg="12">
                {error && error ? <Alert color="danger">{error}</Alert> : null}
                {success && success ? (
                  <Alert color="success">{success}</Alert>
                ) : null}
              </Col>
            </Row>

            <h4 className="card-title mb-4">Aceitar solicitação</h4>
            <FormGroup>
              <Form>
                <Formik
                  enableReinitialize
                  initialValues={{
                    attendanceRequestItems: attendanceRequestItems || [],
                    reason: '',
                  }}
                  validationSchema={Yup.object().shape({
                    attendanceRequestItems: Yup.array().of(
                      Yup.object().shape({
                        scheduleConfirmations: Yup.array().of(
                          Yup.object().shape({
                            checked: Yup.bool(),
                            interpreters: Yup.array().of(Yup.string()),
                          })
                        ),
                      })
                    ),
                    reason: Yup.string(),
                  })}
                  onSubmit={values => {
                    this.handleSubmit(values);
                  }}
                >
                  {({ values, submitForm, setFieldValue }) => {
                    let anyItemDeselected = false;
                    values.attendanceRequestItems?.forEach(item => {
                      item.scheduleConfirmations.forEach(schedule => {
                        if (!schedule.checked) {
                          anyItemDeselected = true;
                        }
                      });
                    });
                    return (
                      <>
                        <FieldArray
                          name="attendanceRequestItems"
                          render={attendanceRequestItemsArrayHelpers => (
                            <>
                              {values.attendanceRequestItems?.map(
                                (preSchedule, attendanceRequestIndex) => {
                                  const attendanceRequestFieldPrefix = `attendanceRequestItems[${attendanceRequestIndex}]`;
                                  return (
                                    <Card key={attendanceRequestIndex}>
                                      <CardBody>
                                        <ScheduleConfirm
                                          schedule={preSchedule.schedule}
                                        />
                                        <div className="my-3">
                                          <Label
                                            for="schedules-form"
                                            className="form-label mt-2 mb-1"
                                          >
                                            Compromissos
                                          </Label>
                                          <FieldArray
                                            name={`${attendanceRequestFieldPrefix}.schedules`}
                                            render={schedulesArrayHelpers => (
                                              <>
                                                {values.attendanceRequestItems[
                                                  attendanceRequestIndex
                                                ].scheduleConfirmations?.map(
                                                  (
                                                    scheduleConfirmation,
                                                    scheduleConfirmationIndex
                                                  ) => {
                                                    const scheduleFieldPrefix = `${attendanceRequestFieldPrefix}.scheduleConfirmations[${scheduleConfirmationIndex}]`;
                                                    return (
                                                      <Col
                                                        key={
                                                          scheduleConfirmationIndex
                                                        }
                                                      >
                                                        <Label className="mt-4 mr-4 pr-4">
                                                          <Field
                                                            type="checkbox"
                                                            name={`${scheduleFieldPrefix}.checked`}
                                                            style={{
                                                              marginRight:
                                                                "10px",
                                                            }}
                                                          />
                                                          <span>
                                                            {`${formatDateTime(
                                                              scheduleConfirmation.date
                                                            )} até ${formatDateTime(
                                                              scheduleConfirmation.dateEnd
                                                            )}`}
                                                          </span>
                                                        </Label>
                                                        {scheduleConfirmation?.checked && (
                                                          <>
                                                            <Field
                                                              name={`${scheduleFieldPrefix}.interpreters`}
                                                              component={
                                                                CustomSelect
                                                              }
                                                              options={
                                                                interpreters
                                                              }
                                                              placeholder="Selecione um ou mais intérpretes"
                                                              isMulti
                                                              className="mb-2"
                                                            />
                                                            {attendanceRequestIndex ===
                                                              0 &&
                                                              scheduleConfirmationIndex ===
                                                              0 &&
                                                              scheduleConfirmation
                                                                .interpreters
                                                                ?.length >
                                                              0 && (
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "flex-end",
                                                                  }}
                                                                >
                                                                  <Button
                                                                    type="button"
                                                                    color="primary"
                                                                    onClick={() =>
                                                                      values.attendanceRequestItems.forEach(
                                                                        (
                                                                          item,
                                                                          itemIndex
                                                                        ) => {
                                                                          item.scheduleConfirmations.forEach(
                                                                            (
                                                                              schedule,
                                                                              scheduleIndex
                                                                            ) => {
                                                                              const itemName = `attendanceRequestItems[${itemIndex}].scheduleConfirmations[${scheduleIndex}]`;
                                                                              setFieldValue(
                                                                                `${itemName}.checked`,
                                                                                true
                                                                              );
                                                                              setFieldValue(
                                                                                `${itemName}.interpreters`,
                                                                                values
                                                                                  .attendanceRequestItems[
                                                                                  attendanceRequestIndex
                                                                                ]
                                                                                  .scheduleConfirmations[
                                                                                  scheduleConfirmationIndex
                                                                                ]
                                                                                  .interpreters
                                                                              );
                                                                            }
                                                                          );
                                                                        }
                                                                      )
                                                                    }
                                                                  >
                                                                    Copiar
                                                                    seleção de
                                                                    intérpretes
                                                                  </Button>
                                                                </div>
                                                              )}
                                                          </>
                                                        )}
                                                      </Col>
                                                    );
                                                  }
                                                )}
                                              </>
                                            )}
                                          />
                                          <ErrorMessage
                                            name="interpreter"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>
                                      </CardBody>
                                    </Card>
                                  );
                                }
                              )}
                            </>
                          )}
                        />
                        {anyItemDeselected && (
                          <Card>
                            <CardBody>
                              <div>
                                <Label for="reason">
                                  Motivo do aceite parcial
                                </Label>
                                <Field
                                  name="reason"
                                  render={({ field, form }) => (
                                    <Input
                                      {...field}
                                      type="text"
                                      className="form-input mt-2 d-flex flex-row-reverse"
                                      placeholder="Informe o motivo pelo qual não foi possível atender à esta solicitação integralmente"
                                    />
                                  )}
                                />
                              </div>
                            </CardBody>
                          </Card>
                        )}
                        <div className="text-center my-3 d-flex flex-row-reverse">
                          <Button
                            color="success"
                            disabled={this.props.loading}
                            onClick={submitForm}
                          >
                            {this.props.loading
                              ? "Aguarde..."
                              : "Aceitar solicitação"}
                          </Button>
                          <button
                            className="d-none"
                            id="submit-event-information"
                          ></button>
                        </div>
                      </>
                    );
                  }}
                </Formik>
              </Form>
            </FormGroup>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

RequestConfirm.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  onGetSchedulesList: PropTypes.func,
  onGetInterpreters: PropTypes.func,
  onAcceptRequest: PropTypes.func,
};

const mapStateToProps = ({ shared, Request }) => {
  let attendanceRequestItems = [];
  if (shared.attendanceRequestItems) {
    attendanceRequestItems = shared.attendanceRequestItems.map(item => {
      return {
        ...item,
        scheduleConfirmations: item.scheduleConfirmations.map(schedule => {
          return {
            ...schedule,
            checked: true,
            interpreters: [],
          };
        }),
      };
    });
  }
  return {
    error: shared.error || Request.error,
    success: shared.success || Request.success,
    attendanceRequestItems,
    interpreters: shared.interpretersSummary,
    loading: Request.loading,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetInterpreters: requestId => dispatch(getInterpreters(requestId)),
  onGetSchedulesList: id => dispatch(getScedulesList(id)),
  onAcceptRequest: (data, history) => dispatch(acceptRequest(data, history)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RequestConfirm)
);
