import {
  API_ERROR,
  GET_USER_ROLES_SUCCESS,
  TOGGLE_CHANGE_MODAL_ACTIVE,
  CHANGE_ROLE_SUCCESS,
  CHANGE_ROLE_STARTED,
  NEW_AUTH_SUCCESS,
  NEW_AUTH_STARTED
} from "./actionTypes";

const initialState = {
  error: "",
  userRoles: [],
  loading: false,
  changeRoleModal: false,
};

const changeRole = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_ROLES_SUCCESS:
      return {
        ...state,
        error: "",
        userRoles: action.payload,
      };

    case TOGGLE_CHANGE_MODAL_ACTIVE:
      return {
        ...state,
        error: "",
        changeRoleModal: !state.changeRoleModal,
      };

    case CHANGE_ROLE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };

    case API_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CHANGE_ROLE_STARTED:
      return {
        ...state,
        loading: true,
      };
 case NEW_AUTH_STARTED:
      return {
        ...state,
        loading: true,
      };  
    case NEW_AUTH_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };

    default:
      return state;
  }
};

export default changeRole;
