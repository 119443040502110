import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// Import Charts
import StackedColumnChart from "./StackedColumnChart";
import { PieChart, Pie, ResponsiveContainer, Cell } from "recharts";
import ReactApexChart from "react-apexcharts"

import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from 'yup'

// Import actions
import {
  getTotalSchedulesData,
  getTotalSolicitations,
  onGetRequestsForChart,
  onGetRequestsForChartInterpreters,
  onGetRequestsForChartCenters,
  onGetTotalInterpretersAndYearSchedules,
} from "../../store/actions";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// i18n
import { withTranslation } from "react-i18next";
import classNames from "classnames";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartsDataCenters: [],
      chartsDataInterpreters: [],
      solicitations: [],
      valor: '',
      filters: [],
      data: [
        { name: "Group A", value: 15, label: 'CCAB' },
        { name: "Group B", value: 5, label: 'CAHL' },
        { name: "Group C", value: 12, label: 'CETENS' },
        { name: "Group D", value: 16, label: 'CETEC' },
      ],
      email: [
        { title: "Semana", linkto: "#", isActive: false },
        { title: "Mês", linkto: "#", isActive: false },
        { title: "Ano", linkto: "#", isActive: true },
      ],
      modal: false,
      subscribemodal: false,
      chartType: "yearly",
      xAxisCategories: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],

      chartSeries2: [
        {
          name: "Série 2",
          data: [],
          color: "#f1b44c"
        }
      ],
      xInterpreters: [''],
      series: [
        {
          data: [],
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },

        colors: ["#34c38f"],
        grid: {
          borderColor: "#f1f1f1",
        },
        xaxis: {
          categories: [
          ],
        },
      },
    }


    this.togglemodal = this.togglemodal.bind(this);
    this.togglesubscribemodal = this.togglesubscribemodal.bind(this);
  }

  componentDidMount() {
    const interpretersNames = Object.values(this.props.chartsDataInterpreters);
    const values = { startDate: "01-01-2024", endDate: null };
    const anoAtual = new Date().getFullYear();
    this.setState({
      options: {
        ...this.state.options,
        xaxis: {
          ...this.state.options.xaxis,
          categories: this.props.chartsDataCenters
        },
        chartSeries2: [
          {
            ...this.state.chartSeries2,
            data: Object.values(this.props.interpretersApprovedRequestsCount),
          },
        ],
      },
      xInterpreters: Object.values(this.props.chartsDataInterpreters),
    });

    const valor = String(anoAtual) + "-01-01";
    const {
      onGetTotalSchedules,
      onGetTotalSolicitations,
      onGetChartsDataInterpreters,
      onGetTotalInterpretersSchedulesYearly,
      onGetChartsData,
      onGetChartsDataCenters,
    } = this.props;

    onGetTotalSchedules();
    onGetTotalSolicitations(values);
    onGetTotalInterpretersSchedulesYearly();
    const payload = { periodType: 'yearly', values }
    onGetChartsDataCenters(payload);
    onGetChartsData(payload);
    onGetChartsDataInterpreters(payload);
    this.filters = { startDate: valor, endDate: null };

  }
  formatInterpretersNames(names) {
    return names.map(name => {
      const parts = name.split(' ');
      if (parts.length > 1) {
       const res_name = `${parts[0]} ${parts[parts.length - 1]}`;
       return res_name;
      }  
      return name;
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.chartsDataCenters !== this.props.chartsDataCenters) {
      this.setState((prevState) => ({
        options: {
          ...prevState.options,
          xaxis: {
            ...prevState.options.xaxis,
            categories: this.props.chartsDataCenters
          }
        }
      }));
    }

    if (prevProps.approvedRequestsCount !== this.props.approvedRequestsCount) {
      this.setState((prevState) => ({
        series: [{
          ...prevState.series,
          data: Object.values(this.props.approvedRequestsCount),
        }],
      }));
    }

    if (prevProps.chartsDataInterpreters !== this.props.chartsDataInterpreters) {
      const formattedInterpreters = this.formatInterpretersNames(this.props.chartsDataInterpreters);
      this.setState((prevState) => ({
        xInterpreters:  formattedInterpreters,
      }));
    }

    if (prevProps.interpretersApprovedRequestsCount !== this.props.interpretersApprovedRequestsCount) {
      this.setState((prevState) => ({

        chartSeries2: [
          {
            ...prevState.chartSeries2,
            data: Object.values(this.props.interpretersApprovedRequestsCount),
          },
        ],

      }));
    }

  }
  toggleSearchButton = (values) => {
    this.filters = values;
    const payload = { periodType: 'yearly', values }
    this.props.onGetTotalSolicitations(values);
    this.props.onGetChartsData(payload)
    this.props.onGetChartsDataInterpreters(payload);
    this.props.onGetChartsDataCenters(payload);
    this.handleChartTypeChange(this.chartType, this.filters)
    this.handleChartsData2(this.chartType)
    this.setState({
      options: {
        ...this.state.options,
        xaxis: {
          ...this.state.options.xaxis,
          categories: this.props.chartsDataCenters
        }
      }
    });
  }

  handleChartsData2() {
    const payload = { values: this.filters }
    this.props.onGetChartsData(this.filters);
  }

  handleChartsData(chartType) {
    const payload = { periodType: chartType, values: this.filters }
    this.props.onGetChartsData(payload);
  }

  handleChartTypeChange(chartType, filters) {
    if (!chartType) {
      return;
    }
    let xAxisCategories = [];
    if (chartType === 'weekly') {
      xAxisCategories = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom'];
    } else if (chartType === 'monthly') {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const daysInMonth = new Date(currentDate.getFullYear(), currentMonth + 1, 0).getDate();
      for (let i = 1; i <= daysInMonth; i++) {
        xAxisCategories.push(i);
      }
    } else if (chartType === 'yearly') {
      xAxisCategories = [
        'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
        'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
      ];
    }

    this.setState({
      chartType,
      xAxisCategories
    });
  }

  togglemodal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  togglesubscribemodal = () => {
    this.setState((prevState) => ({
      subscribemodal: !prevState.subscribemodal,
    }));
  };

  render() {
    //meta title
    document.title = "Dashboard | Atendimento NUPI";
    const anoAtual = new Date().getFullYear();
    const valor = String(anoAtual) + "-01-01";
    const series = [
      {
        name: "Solicitações",
        data: this.props.chartsData,
        type: this.props.periodType,
        filters: this.props.filters,
      },
    ];

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Dashboards")}
              breadcrumbItem={this.props.t("Dashboard")}
            />
            <Row>
              <Col xl="4" >
                <WelcomeComp data={this.props.misc} />
                <MonthlyEarning schedulesTotal={this.props.schedules} />

              </Col>
              <Col xl="8">
                <Card>
                  <CardBody >
                    <div className="d-sm-flex flex-wrap">
                      <CardTitle className="card-title mb-4 h4">Solicitações atendidas</CardTitle>
                      <div className="ms-auto">
                        <ul className="nav nav-pills">
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={classNames(
                                { active: this.state.chartType === "weekly" },
                                "nav-link"
                              )}
                              onClick={() => {
                                this.handleChartTypeChange("weekly");
                                this.handleChartsData("weekly");
                              }}
                              id="one_day"
                            >
                              Semana
                            </Link>{" "}
                          </li>
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={classNames(
                                { active: this.state.chartType === "monthly" },
                                "nav-link"
                              )}
                              onClick={() => {
                                this.handleChartTypeChange("monthly");
                                this.handleChartsData("monthly");
                              }}
                              id="one_week"
                            >
                              Mês
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={classNames(
                                { active: this.state.chartType === "yearly" },
                                "nav-link"
                              )}
                              onClick={() => {
                                this.handleChartTypeChange("yearly");
                                this.handleChartsData("yearly");
                              }}
                              id="one_month"
                            >
                              Ano
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="clearfix" />
                    <StackedColumnChart
                      chartType={this.state.chartType}
                      chartSeries={series}
                      periodData={this.props.filters}
                      xAxisCategories={this.state.xAxisCategories}
                    />
                  </CardBody>
                </Card>

              </Col>
            </Row>
            <Col>
              <Row>
                <Col xl="12">
                  <Card>
                    <Row>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          startDate: valor,
                          endDate: "",
                        }}
                        onSubmit={values => {
                          this.props.submit(values);
                        }}
                        validationSchema={
                          Yup.object().shape({
                            startDate: Yup.date().required('Informe a data inicial'),
                            endDate: Yup.date()
                              .nullable()
                              .required('Informe a data final')
                              .when('startDate', {
                                is: (start) => !!start,
                                then: Yup.date().test({
                                  message: 'A data final deve ser maior ou igual à data inicial',
                                  test: function (value) {
                                    const startDate = this.resolve(Yup.ref('startDate'));
                                    return !startDate || !value || value >= startDate;
                                  },
                                }),
                              }),
                          })
                        }
                      >
                        {({ values, errors, touched, handleSubmit }) => (
                          <Form className='form-horizontal'>
                            <Row>
                              <CardTitle className="card-title mb-2 h4" style={{ marginLeft: "1em", marginTop: "1em" }}>Gestão de demanda</CardTitle>
                              <Col md="5">
                                <CardBody>
                                  <Label for="startDate" className='form-label'>
                                    Data inicial
                                  </Label>
                                  <Field
                                    type="date"
                                    name="startDate"
                                    id="startDate"
                                    className={
                                      'form-control' +
                                      (errors.startDate && touched.startDate ? ' is-invalid' : '')
                                    }
                                  />
                                  <ErrorMessage
                                    name="startDate"
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </CardBody>
                              </Col>
                              <Col md="7">
                                <CardBody  >
                                  <Label for="endDate" className='form-label'>
                                    Data final
                                  </Label>
                                  <Row>
                                    <Col md="8">
                                      <Field
                                        type="date"
                                        name="endDate"
                                        id="endDate"
                                        className={
                                          'form-control' +
                                          (errors.endDate && touched.endDate ? ' is-invalid' : '')
                                        }
                                      />
                                      <ErrorMessage
                                        name="endDate"
                                        component='div'
                                        className='invalid-feedback'
                                      />
                                    </Col>
                                    <Col md="4">
                                      <Button
                                        type="button"
                                        color="primary"
                                        className="btn-primary"
                                        onClick={() => this.toggleSearchButton(values)}
                                      >
                                        Pesquisar
                                      </Button>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </Formik>
                    </Row>
                  </Card>

                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Card>
                    <div style={{ width: "100%", height: "34em", marginTop: "2em" }}>
                      <CardTitle className="card-title mb-4 h4" style={{ marginLeft: "2em" }} >Demanda por setor</CardTitle>
                      <h1></h1>
                      <ResponsiveContainer>
                        <React.Fragment>
                          <ReactApexChart
                            options={this.state.options}
                            series={this.state.series}
                            type="bar"
                            height="350"
                            className="apex-charts"
                          />
                        </React.Fragment>
                      </ResponsiveContainer>
                    </div>
                  </Card>
                </Col>
                <Col md="8">
                  <Link to="/my-requests">
                    <Row>
                      <Col md="4">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">Solicitações</p>
                                <h4 className="mb-0">{this.props.solicitations.total}</h4>
                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                <span className="avatar-title">
                                  <i className={"bx bx-chart font-size-24"} />
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">Pendentes</p>
                                <h4 className="mb-0">{this.props.solicitations.totalPeding}</h4>
                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                <span className="avatar-title">
                                  <i className={"bx bx-alarm font-size-24"} />
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">Aceitas</p>
                                <h4 className="mb-0">{this.props.solicitations.totalApproved}</h4>
                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                <span className="avatar-title">
                                  <i className={"bx bx-calendar-check font-size-24"} />
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Link>
                  <Card>
                    <CardBody >
                      <CardTitle className="card-title mb-4 h4">Demanda da equipe</CardTitle>
                      <div className="clearfix" />
                      <StackedColumnChart
                        chartSeries={this.state.chartSeries2}
                        xAxisCategories={this.state.xInterpreters}
                        color={"secondary"}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>
      </React.Fragment >
    );
  }
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  chartsDataCenters: PropTypes.any,
  chartsDataInterpreters: PropTypes.any,
  approvedRequestsCount: PropTypes.any,
  interpretersApprovedRequestsCount: PropTypes.any,
  chartsType: PropTypes.string,
  onGetChartsData: PropTypes.func,
  onGetChartsDataInterpreters: PropTypes.func,
  onGetTotalfilters: PropTypes.func,
  onGetTotalSolicitations: PropTypes.func,
  onGetTotalInterpretersSchedulesYearly: PropTypes.func,
};

const mapStateToProps = (state) => {
  const chartsDataCenters = state.Dashboard.chartsDataCenters || {};
  const chartsDataInterpreters = state.Dashboard.chartsDataInterpreters || {};
  const dataCenters = chartsDataCenters.data || {};
  const dataInterpreters = chartsDataInterpreters.data || {};

  return {
    chartsData: state.Dashboard.chartsData,
    chartsDataCenters: dataCenters.sectorNames || [],
    chartsDataInterpreters: dataInterpreters.interpreterNames || [],
    approvedRequestsCount: dataCenters.approvedRequestsCount || {},
    interpretersApprovedRequestsCount: dataInterpreters.requestsByInterpreter || {},
    chartsType: state.Dashboard.chartsType,
    schedules: state.Dashboard.schedules,
    solicitations: state.Dashboard.solicitations,
    misc: state.Dashboard.misc,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetChartsData: (payload) => dispatch(onGetRequestsForChart(payload)),
  onGetChartsDataInterpreters: (payload) => dispatch(onGetRequestsForChartInterpreters(payload)),
  onGetChartsDataCenters: (payload) => dispatch(onGetRequestsForChartCenters(payload)),
  onGetTotalSchedules: () => dispatch(getTotalSchedulesData()),
  onGetTotalSolicitations: (filters) => dispatch(getTotalSolicitations(filters)),
  onGetTotalInterpretersSchedulesYearly: () => dispatch(onGetTotalInterpretersAndYearSchedules()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Dashboard));
