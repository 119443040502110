import { api } from "services/api"
import {
  GET_EVENTS_FAIL,
  GET_EVENTS_SUCCESS,
  ADD_NEW_EVENT,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAIL,
  UPDATE_EVENT,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAIL,
  DELETE_EVENT,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAIL,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_MEEETING_SUCCESS,
  CLOSE_MODAL,
} from "./actionTypes"
import { toast } from "react-toastify"

export const getEvents = (data) => (dispatch) => {
  api.post('/schedule_confirmation/all', data, { withCredentials: true }).then(data => {
    if (data && data.status == 200) {
      dispatch({ type: GET_EVENTS_SUCCESS, payload: data.data })
    } else {
      dispatch({ type: GET_EVENTS_FAIL, payload: error })
    }
  }).catch(function (error) {
    if(error.response){
      toast.warning(error.response.data.errors[0].message)  
    }
    dispatch({ type: GET_EVENTS_FAIL, payload: error.response.data })
  })
}

export const getEventsCalendar = (data) => (dispatch) => {
  api.get(`/schedule_confirmation/calendar?dateIntervalStart=${data.dateIntervalStart}&dateIntervalEnd=${data.dateIntervalEnd}`, { withCredentials: true })
    .then(response => {
      if (response && response.status === 200) {
        dispatch({ type: GET_EVENTS_SUCCESS, payload: response.data })
      } else {
        dispatch({ type: GET_EVENTS_FAIL, payload: payload.data})
      }
    })
    .catch(function (error) {
      if(error.response){
        toast.warning(error.response.data.errors[0].message)  
      }
      dispatch({ type: GET_EVENTS_FAIL, payload: error.response.data })
    })
}



export const getEventsInterpreterSelected = (data) => (dispatch) => {
  api.post('/schedule_confirmation/selected', data, { withCredentials: true }).then(data => {
    if (data && data.status == 200) {
      dispatch({ type: GET_EVENTS_SUCCESS, payload: data.data })
    } else {
      dispatch({ type: GET_EVENTS_FAIL, payload: error })
    }
  }).catch(function (error) {
    dispatch({ type: GET_EVENTS_FAIL, payload: error })
  })
}

export const getEventsInterpreterSelectedSuccess = events => ({
  type: GET_EVENTS_SUCCESS,
  payload: events,
})

export const getMeetingDetailsAdmin = (data) => (dispatch) => {
  
  api.get(`/schedule_confirmation/admin/${data.meetingId}`, {
    params: {
      userId: data.userId
    },
    withCredentials: true
  }).then(data => {
    if (data && data.status == 200) {
      dispatch({ type: GET_MEEETING_SUCCESS, payload: data.data })
    } else {
      dispatch({ type: GET_EVENTS_FAIL, payload: error })
    }
  }).catch(function (error) {
    dispatch({ type: GET_EVENTS_FAIL, payload: error })
  })
}

export const getMeetingDetails = (data) => (dispatch) => {
  api.get('/schedule_confirmation/all_from/' + data, { withCredentials: true }).then(data => {
    if (data && data.status == 200) {
      dispatch({ type: GET_MEEETING_SUCCESS, payload: data.data })
    } else {
      dispatch({ type: GET_EVENTS_FAIL, payload: error })
    }
  }).catch(function (error) {
    dispatch({ type: GET_EVENTS_FAIL, payload: error })
  })
}

export const getEventsSuccess = events => (dispatch) => {
}

export const getEventsFail = error => ({
  type: GET_EVENTS_FAIL,
  payload: error,
})

export const addNewEvent = event => ({
  type: ADD_NEW_EVENT,
  payload: event,
})

export const addEventSuccess = event => ({
  type: ADD_EVENT_SUCCESS,
  payload: event,
})

export const addEventFail = error => ({
  type: ADD_EVENT_FAIL,
  payload: error,
})

export const updateEvent = event => ({
  type: UPDATE_EVENT,
  payload: event,
})

export const updateEventSuccess = event => ({
  type: UPDATE_EVENT_SUCCESS,
  payload: event,
})

export const updateEventFail = error => ({
  type: UPDATE_EVENT_FAIL,
  payload: error,
})

export const deleteEvent = event => ({
  type: DELETE_EVENT,
  payload: event,
})

export const deleteEventSuccess = event => ({
  type: DELETE_EVENT_SUCCESS,
  payload: event,
})

export const deleteEventFail = error => ({
  type: DELETE_EVENT_FAIL,
  payload: error,
})

export const getCategories = () => ({
  type: GET_CATEGORIES,
})

export const getCategoriesSuccess = categories => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: categories,
})

export const getCategoriesFail = error => ({
  type: GET_CATEGORIES_FAIL,
  payload: error,
})

export const closeModal = () => ({
  type: CLOSE_MODAL,
})